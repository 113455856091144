<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
        <ng-container>
            <form #f="ngForm" [formGroup]="call" novalidate>
                <div class="modal-header align-items-center " >
                    <span class="icon icon-cirlce">
                        <img class="icon-light" src="../assets/images/header/phone-icon.png" alt="" aria-hidden="true" width="44" />
                        <img class="icon-dark" src="../assets/images/header/phone-icon-dark.png" alt="" aria-hidden="true" width="44" />
                    </span>
                    <h6 class="modal-title w-100 text-center" id="modal-basic-title"></h6>
                    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- caller.component.html -->
                <div class="modal-body" >
                    <div class="card-form-content card-form-success mb-4" *ngIf="loanID">
                        <select class="form-control form-control-sm " (change)="onPhoneSelect($event)">
                            <!-- Iterate over each contact and list their phone types -->
                            <ng-container *ngFor="let contact of contactList">
                                <!-- Mobile Phone -->
                                <option 
                                    *ngIf="contact.mobile_phone" 
                                    [value]="contact.id + '-mobile'" 
                                    [ngClass]="{'dnc-checked': (loanDetail.dnc_list_mobile_calling_a1 === 'TRUE' && contact.applicant_type === 'APPLICANT') || 
                                    (loanDetail.dnc_list_mobile_calling_a2 === 'TRUE' && contact.applicant_type === 'CO-APPLICANT') || 
                                    (loanDetail.dnc_list_mobile_calling_c1 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-1') || 
                                    (loanDetail.dnc_list_mobile_calling_c2 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-2') ||
                                    (loanDetail.dnc_list_mobile_calling_c === 'TRUE' && contact.applicant_type === 'COMPANY') || 
                                    (loanDetail.dnc_list_mobile_calling_r === 'TRUE' && contact.applicant_type === 'RECIPIENT') }">
                                    {{ contact.applicant_type | titlecase }} - {{ formatName(contact) }} (Mobile Phone)
                                </option>
                    
                                <!-- Home Phone -->
                                <option 
                                    *ngIf="contact.home_phone" 
                                    [value]="contact.id + '-home'" 
                                    [ngClass]="{'dnc-checked': loanDetail.dnc_list_home_phone_calling_a1 === 'TRUE' && contact.applicant_type === 'APPLICANT' ||
                                     loanDetail.dnc_list_home_phone_calling_a2 === 'TRUE' && contact.applicant_type === 'CO-APPLICANT' ||
                                     loanDetail.dnc_list_home_phone_calling_c1 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-1' ||
                                     loanDetail.dnc_list_home_phone_calling_c2 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-2' ||
                                     loanDetail.dnc_list_home_phone_calling_c === 'TRUE' && contact.applicant_type === 'COMPANY' ||
                                     loanDetail.dnc_list_home_phone_calling_r === 'TRUE' && contact.applicant_type === 'RECIPIENT' }">
                                    {{ contact.applicant_type | titlecase }} - {{ formatName(contact) }} (Home Phone)
                                </option>
                    
                                <!-- Work Phone -->
                                <option 
                                    *ngIf="contact.work_phone" 
                                    [value]="contact.id + '-work'" 
                                    [ngClass]="{'dnc-checked': loanDetail.dnc_list_work_phone_calling_a1 === 'TRUE' && contact.applicant_type === 'APPLICANT' ||
                                     loanDetail.dnc_list_work_phone_calling_a2 === 'TRUE' && contact.applicant_type === 'CO-APPLICANT' ||
                                     loanDetail.dnc_list_work_phone_calling_c1 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-1' ||
                                     loanDetail.dnc_list_work_phone_calling_c2 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-2' ||
                                     loanDetail.dnc_list_work_phone_calling_c === 'TRUE' && contact.applicant_type === 'COMPANY' ||
                                     loanDetail.dnc_list_work_phone_calling_r === 'TRUE' && contact.applicant_type === 'RECIPIENT'}">
                                    {{ contact.applicant_type | titlecase}} - {{ formatName(contact) }} (Work Phone)
                                </option>
                            </ng-container>
                        </select>
                        <div class="text-danger" *ngIf="isDNC">
                            Selected number is in DNC.
                        </div>

                        </div>
                    <div class="phone">
                        <div class="phone-keys">
                            <input type="text" *ngIf="maskOff" class="phone-screen" [value]="dailing_no | mask: US_PHONE_MASKING" placeholder="(000) 000-0000" />
                            <input type="text" *ngIf="!maskOff" class="phone-screen" [value]="selectedPhoneNumber | mask: US_PHONE_MASKING" placeholder="(000) 000-0000" />
                            <button type="button" class="action btn" [ngClass]="{'disabled':isDNC}" (click)="phoneCallStart()">
                                {{"Call" | translate}}
                            </button>
                            <!-- <button type="button" class="operator btn disabled">{{"Mute"|translate}}</button>
                            <button type="button" class="operator btn disabled">{{"Hold"|translate}}</button>
                            <button type="button" class="operator btn disabled">{{"Transfer"|translate}}</button>
                            <button type="button" class="operator btn disabled">{{"Line 1"|translate}}</button>
                            <button type="button" class="operator btn disabled">{{"Line 2"|translate}}</button>
                            <button type="button" class="operator btn disabled">{{"To Account"|translate}}</button> -->
                        </div>
                    </div>   
                </div>
            </form>
        </ng-container>
    </div>
</div>