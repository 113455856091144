<div
  class="draggable-container"
  cdkDrag
  cdkDragBoundary=".modal"
  cdkDragRootElement="draggable-container"
>
  <div class="draggable-modal-content " >
    <ng-container>
      <div class="modal-header align-items-center cursor-pointer" cdkDragHandle>
        <h6 class="modal-title modal-title-success">Manual Charge Off</h6>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="activeModal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="lms-module-card">
          <div class="card-body">
            <div class="card-form-content card-form-success">
              <div class="bg-note-info p-2 mb-3">
                Click submit to manually charge off the loan.
              </div>
              <div class="table-responsive">
                <table
                  class="table table-sm table-no-bg table-align-middle mb-0"
                >
                  <thead>
                    <tr>
                      <td class="border-0">Field Name</td>
                      <td class="border-0">Before</td>
                      <td class="border-0"></td>
                      <td class="border-0 lms-text-light-purple">After</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Status</td>
                      <td>
                        {{ manualChargeOffPreview?.before?.status }}
                      </td>
                      <td>
                        <img
                          class="img-fluid"
                          src="assets/images/icons/arrow2.png"
                          alt=""
                        />
                      </td>
                      <td class="lms-text-light-purple">
                        {{ manualChargeOffPreview?.after?.status }}
                      </td>
                    </tr>

                    <tr>
                      <td>Sub-Status</td>
                      <td> {{ manualChargeOffPreview?.before?.sub_status }}</td>
                      <td>
                        <img
                          class="img-fluid"
                          src="assets/images/icons/arrow2.png"
                          alt=""
                        />
                      </td>
                      <td class="lms-text-light-purple">
                        {{ manualChargeOffPreview?.after?.sub_status }}
                      </td>
                    </tr>

                    <tr>
                      <td>Charge-Off Date</td>
                      <td>{{ "-" }}</td>
                      <td>
                        <img
                          class="img-fluid"
                          src="assets/images/icons/arrow2.png"
                          alt=""
                        />
                      </td>
                      <td class="lms-text-light-purple">
                        {{ afterManualChargeOffDate | date : DATE_FORMAT_MMDDYYYY }}
                      </td>
                    </tr>

                    <tr>
                      <td>Charge-Off Amount</td>
                      <td>{{ manualChargeOffPreview?.before?.chargeOfAmmount  | currency }}</td>
                      <td>
                        <img
                          class="img-fluid"
                          src="assets/images/icons/arrow2.png"
                          alt=""
                        />
                      </td>
                      <td class="lms-text-light-purple">
                        {{ manualChargeOffPreview?.after?.chargeOfAmmount  | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <form #f="ngForm" [formGroup]="manualChargeOffForm" novalidate>
                <ng-container *ngIf="refresh && manualChargeOffPreview?.addToInventory">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row gutters-5">
                      <div class="form-group col-lg-3">
                        <label class="form-group-label"
                          >Add to Inventory?</label
                        >
                      </div>
                      <div class="form-group col-lg-2">
                        <select
                          formControlName="add_inventory"
                          name="add_inventory"
                          class="form-control form-control-sm"
                          (change)="dynamicRequiredSetValidators()"
                        >
                          <option value="NO">No</option>
                          <option value="YES">Yes</option>
                        </select>
                        <val-errors controlName="add_inventory">
         
                          <ng-template valError="required">Value is required.</ng-template>
  
                      </val-errors>
                      </div>

                      <div class="form-group col-lg-3">
                        <label class="form-group-label">Stock Number</label>
                      </div>
                      <div class="form-group col-lg-4">
                        <input
                          type="text"
                          formControlName="stock_number"
                          name="stock_number"
                          readonly
                          class="form-control form-control-sm"
                          value="AP12674"
                        />
                        <val-errors controlName="stock_number">
         
                          <ng-template valError="required">Stock Number is required.</ng-template>
  
                      </val-errors>
                      </div>
                      
                    </div>

                    <!------------------------Pmt Received Method End-------------------------------->
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="row gutters-5">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label">Sales Location</label>
                      </div>
                      <div class="form-group col-lg-7">
                        <select
                          formControlName="sales_location"
                          name="sales_location"
                          class="form-control form-control-sm">
                          <option [ngValue]="null">-Select-</option>
                          <option [value]="salesLocation"   *ngFor="let salesLocation of salesLocationArray; let i = index">{{salesLocation}}</option>
                        </select>
                        <val-errors controlName="sales_location">
         
                            <ng-template valError="required">Sales Location is required.</ng-template>

                        </val-errors>
                      </div>
                    </div>
                    <!------------------------Pmt Received Method End-------------------------------->
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="row gutters-5">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label">Mileage</label>
                      </div>
                      <div class="form-group col-lg-3">
                        <input
                          type="text"
                          formControlName="mileage"
                          thousandSeparator=","
                          mask="separator.0"
                          name="mileage"
                          class="form-control form-control-sm"
                          value="127845"
                        />
                        <val-errors controlName="mileage">
                          <ng-template valError="currencyRange"
                            >Value must be between 0 and 99999999.</ng-template
                            >
                            <ng-template valError="required">Mileage is required.</ng-template>

                        </val-errors>
                      </div>
                    </div>
                    <!------------------------Pmt Received Method End-------------------------------->
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="row gutters-5">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label">Mileage Status</label>
                      </div>
                      <div class="form-group col-lg-7">
                        <select
                          formControlName="mileage_status"
                          name="mileage_status"
                          class="form-control form-control-sm"
                        >
                          <option [value]="mileageStatus.slug" *ngFor="let mileageStatus of mileageStatusArray; let i = index">{{mileageStatus.label}}</option>
                        </select>
                        <val-errors controlName="mileage_status">
                            <ng-template valError="required">Mileage Status is required.</ng-template>
                        </val-errors>
                      </div>
                    </div>
                    <!------------------------Pmt Received Method End-------------------------------->
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row gutters-5">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label">Collateral Actual Cash Value</label>
                      </div>
                      <div class="form-group col-lg-3">
                        <input
                          type="text"
                          prefix="$"
                          mask="separator.2"
                          thousandSeparator=","
                          formControlName="collateral_actual_cash_value"
                          name="collateral_actual_cash_value"
                          class="form-control form-control-sm"
                          value=""
                          (blur)="onAmountChange()"
                          onfocus="select()"
                        />
                        <val-errors controlName="collateral_actual_cash_value">
                          <ng-template valError="currencyRange"
                            >Value must be between 0 and 99999999.</ng-template
                            >
                            <ng-template valError="required">Value is required.</ng-template>
                        </val-errors>
                      </div>
                      <!-- <div class="form-group col-lg-3">
                        <a href="#" class="font-size-14 text-primary text-underline mb-0"
                        style="pointer-events: none;" >Pull Manheim MMR Value</a>
                      </div> -->
                    </div>
                    <!------------------------Pmt Received Method End-------------------------------->
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="row gutters-5">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label">{{'VIN'|translate}}</label>
                      </div>
                      <div class="form-group col-lg-3">
                        <input
                        type="text"
                        name="VIN"
                        class="form-control form-control-sm"
                        value=""
                        formControlName="vin"
                        readonly
                      />
                      <val-errors controlName="vin">
                        <ng-template valError="required">VIN is required.</ng-template>
                        <ng-template valError="pattern"
                        >{{'VIN is not well formed'|translate}}</ng-template>
                        <ng-template valError="maxlength">{{'VIN cannot be longer than 100 characters'|translate}}.</ng-template>
                      </val-errors>
                      </div>
                      <!-- <div class="form-group col-lg-3">
                        <a href="#" class="font-size-14 text-primary text-underline mb-0"
                        style="pointer-events: none;" >Pull Manheim MMR Value</a>
                      </div> -->
                    </div>
                    <!------------------------Pmt Received Method End-------------------------------->
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!refresh && manualChargeOffPreview?.addToInventory">
                Loading... Please Wait!
              </ng-container>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <div></div>
        <div>
          <button
            *ngIf="manualChargeOffLoans"
            type="button"
            class="btn btn-success-light btn-150"
            (click)="submit()"
          >
            Submit
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- Override Modal
<ng-template #GetApprovalModal let-modal>
<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
  <div class="modal-header " >
    <h6 class="modal-title text-center mb-3">Get Approval</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form #f="ngForm" [formGroup]="appform" (ngSubmit)="verifyPin()" novalidate>
  <div class="modal-body pb-0 px-0">


    <div class="lms-module-card">
      <div class="card-body">
        <div class="card-form form-vertical">
          <div class="form-group">
            <label class="form-group-label">Initials</label>
            <input type="text" name="employee_initials" formControlName="employee_initials" class="form-control form-control-sm" value="EB1" />
            <val-errors controlName="employee_initials">
              <ng-template valError="required">Initial is required</ng-template>
            </val-errors>
          </div>
          <div class="form-group">
            <label class="form-group-label">PIN</label>
            <input type="password" name="pin" formControlName="pin" class="form-control form-control-sm" value="212121" />
            <val-errors controlName="pin">
              <ng-template valError="required">PIN is required</ng-template>
            </val-errors>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" (click)="this.appform.valid ?  modal.dismiss('Cross click') : ''" class="btn btn-primary btn-block">
      Get Approval
    </button>
  </div>
</form>
</div>
</div>
</ng-template> -->
