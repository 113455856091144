<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
  <div class="draggable-modal-content">
    <form ngForm [formGroup]="addActivityForm" (ngSubmit)="onActivitySubmit(GetApprovalModal)" novalidate>
      <div class="modal-header align-items-center cursor-pointer" cdkDragHandle >
    <h6 class="modal-title modal-title-success">{{'New Note'|translate}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <div class="lms-module-card">
      <div class="card-body">
        <div class="card-form-content card-form-success">
          <div class="row gutters-5 mb-3">
            <div class="form-group col-lg-5">
              <label class="form-group-label field-required">{{'Activity Type'|translate}}</label>
            </div>
            <div class="form-group col-lg-5">
              <select formControlName="activity_type_id"
              name="activity_type_id" id="activity_type_id"
              class="form-control form-control-sm"
              (change)="getSelectedItem($event)"
                >
                <option value="">{{'-Select-'|translate}}</option>
                <option *ngFor="let item of activityTypes" [ngValue]="item.id">
                  {{ item.title }}
                </option>
              </select>
            </div>
            <val-errors controlName="activity_type_id">
              <ng-template valError="required" class="pt-2">{{'Activity Type is required'|translate}}</ng-template>
            </val-errors>
          </div>
          <div class="row gutters-5 mb-3" *ngIf="isAcivityTypeSelected&&(addActivityForm?.controls?.activity_type_id?.value==1||
          addActivityForm?.controls?.activity_type_id?.value==2||
          addActivityForm?.controls?.activity_type_id?.value==3||
          addActivityForm?.controls?.activity_type_id?.value==4||
          addActivityForm?.controls?.activity_type_id?.value==5||
          addActivityForm?.controls?.activity_type_id?.value==6||
          addActivityForm?.controls?.activity_type_id?.value==9)">
            <div class="form-group col-lg-5">
              <label class="form-group-label field-required">{{'Phone Number'|translate}}</label>
            </div>
            <div class="form-group col-lg-5">
              <select formControlName="phone_number"
              name="phone_number" value=""
              class="form-control form-control-sm"
              (change)="otherPhoneNumber()"
                >
                <option [ngValue]="null">{{'-Select-'|translate}}</option>
                <option *ngFor="let item of associated_phone_numbers" [ngValue]="item" >
                  {{ (item?.title  | titlecase) }}{{' '}}{{ (item?.number  | mask: US_PHONE_MASKING) }}
                </option>
                <option value='other'>{{'Other Phone'|translate}}</option>
              </select>
            </div>
            <val-errors controlName="phone_number">
              <ng-template valError="required" class="pt-2">{{'Phone Number is required'|translate}}</ng-template>
            </val-errors>
          </div>
          <div class="row gutters-5 mb-3" *ngIf="isAcivityTypeSelected&&(addActivityForm?.controls?.phone_number?.value=='other')">
            <div class="form-group col-lg-5">
              <label class="form-group-label field-required">{{'Other Number'|translate}}</label>
            </div>
            <div class="form-group col-lg-5">
              <input type="text" name="other_phone" formControlName="other_phone"
                    class="form-control form-control-sm"
                    [mask]="US_PHONE_MASKING" value="" placeholder="(000) 000-0000"
                    />
            </div>
            <val-errors controlName="other_phone">
              <ng-template valError="required" class="pt-2">{{'Other Number is required'|translate}}</ng-template>
            </val-errors>
          </div>
          <div class="row gutters-5" *ngIf="isAcivityTypeSelected">
            <div class="form-group col-lg-12">
              <label class="form-group-label field-required">{{'Activity Note'|translate}}</label>
            </div>
            <div class="form-group col-lg-12">
              <textarea name="note" formControlName="note" (change)="onNoteValueChange($event)" class="form-control" rows="5" class="form-control"
                height="200px" value=""></textarea>
            </div>
            <val-errors controlName="note">
              <ng-template valError="required" class="pt-2">{{'Activity Note is required'|translate}}</ng-template>
            </val-errors>
          </div>

          <div class="row gutters-5 mt-3" *ngIf="isAcivityPTPActive">
            <div class="col-lg-6">
              <div class="row">
                <div class="form-group col-lg-4">
                  <label class="form-group-label oflow">{{'PTP Date'|translate}}</label>
                </div>
                <div class="form-group col-lg-7">
                  <app-date-picker formControlName="ptp_date"  #ptpdate name="ptp_date"  (dateSelected)="futureDateCheck(ptpdate.value)"></app-date-picker>
                </div>
                <!-- <val-errors controlName="ptp_date">
                  <ng-template valError="required" class="pt-2">PTP Date is required</ng-template>
                </val-errors> -->

              </div>
            </div>
            <div class="col-lg-6">
              <div class="row">
                <div class="form-group col-lg-5">
                  <label class="form-group-label oflow">{{'PTP Amount'|translate}}</label>
                </div>
                <div class="form-group col-lg-6">
                  <input type="text" name="ptp_amount" formControlName="ptp_amount"
                    class="form-control form-control-sm" value=""
                    prefix="$"
                    mask="separator.2"
                    thousandSeparator=","
                    (change)="getAmount($event,1)"
                    />
                </div>

                <!-- <val-errors controlName="ptp_amount">
                  <ng-template valError="required" class="pt-2">PTP Amount is required</ng-template>
                </val-errors> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row px-3 py-2 " >
    <div class="col-md-8 cursor-pointer" cdkDragHandle>
      <!-- <span class="d-block font-size-14 lms-text-light-pink">
        PTP Date must be in the future.
      </span> -->
      <!-- <div class="d-block font-size-14 lms-text-light-pink" *ngIf="
          addActivityForm.controls.activity_type_id.errors?.required &&
          addActivityFormSubmit
        ">
        Activity type is required
      </div> -->
      <div class="d-block font-size-14 lms-text-light-pink" *ngIf="
          addActivityForm.controls.note.errors?.required &&
          addActivityFormSubmit
        ">
        {{'Note is required'|translate}}
      </div>
      <span class="d-block font-size-14 lms-text-light-pink" *ngIf="errormsg!='' && isAcivityPTPActive">
     {{errormsg}}
    </span>
    <!-- <span class="d-block font-size-14 lms-text-light-pink" *ngIf="isPastDate">
      PTP Date must be in the future.
  </span> -->
  <val-errors controlName="ptp_date" *ngIf="isAcivityPTPActive">
    <ng-template valError="invalidPTPDate" class="d-block font-size-14 lms-text-light-pink"> {{'PTP Date must be in the future.'|translate}} </ng-template>
  </val-errors>

  

  <span class="d-block font-size-14 lms-text-light-pink" *ngIf="!activityNotePermistion && selectedItem!=''">
    {{"You don’t have the permission to process extensions."|translate}}
 </span>

    </div>
    <div class="col-md-4">
      <button type="submit" class="btn btn-success-light btn-150" [disabled]="!activityNotePermistion" >
        {{'Submit'|translate}}
      </button>
      <!-- <button type="submit" class="btn btn-secondary btn-150" [disabled]="isButtonDisable"  *ngIf="activityNotePermistion && isButtonDisable">
        Submit
      </button> -->


      <button type="submit" class="btn btn-pink btn-150"   *ngIf="!activityNotePermistion && selectedItem!='' && !isButtonDisable">
        {{'Get Approval'|translate}}
      </button>

      <button type="button" class="btn btn-secondary btn-150" [disabled]="isButtonDisable"  class="btn btn-pink btn-150" *ngIf="!activityNotePermistion && selectedItem!='' && isButtonDisable">
        {{'Get Approval'|translate}}
      </button>
    </div>
  </div>
</form>
</div>
</div>






<!-----------Modal----------->

<!-- Override Modal -->
<ng-template #GetApprovalModal let-modal>
  <div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
  <div class="modal-header " >

  <h6 class="modal-title text-center mb-3">{{'Get Approval'|translate}}</h6>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">×</span>
  </button>
</div>

<form #f="ngForm" [formGroup]="form" (ngSubmit)="verifyPin()" novalidate>
<div class="modal-body pb-0 px-0">


  <div class="lms-module-card">
    <div class="card-body">
      <div class="card-form form-vertical">
        <div class="form-group">
          <label class="form-group-label">{{'Initials'|translate}}</label>
          <input type="text" name="employee_initials" formControlName="employee_initials" class="form-control form-control-sm" value="EB1" />
          <val-errors controlName="employee_initials">
            <ng-template valError="required">Initial is required</ng-template>
          </val-errors>
        </div>
        <div class="form-group">
          <label class="form-group-label">{{'PIN'|translate}}</label>
          <input type="password" name="pin" formControlName="pin" class="form-control form-control-sm" value="212121" />
          <val-errors controlName="pin">
            <ng-template valError="required">{{'PIN is required'|translate}}</ng-template>
          </val-errors>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="submit" (click)="this.form.valid ?  modal.dismiss('Cross click') : ''" class="btn btn-primary btn-block">
    {{'Get Approval'|translate}}
  </button>
</div>
</form>
</div>
</div>
</ng-template>
