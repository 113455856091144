import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { PathConfig } from 'src/app/core/config/path.config';
import { UserDetail } from 'src/app/shared/models/user/user-detail.model';
import * as _ from 'lodash';
import { Utility } from 'src/app/core/utils/utility';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manual-charge-off',
  templateUrl: './manual-charge-off.component.html',
  styleUrls: ['./manual-charge-off.component.scss']
})
export class ManualChargeOffComponent implements OnInit {

  @Input() loanDetail!: any;
  @Input() loanID!: any;
  @Input() isRouteShowHistory : boolean = false;

  /**
   * charge-Off Account
   */
  userList: Array<UserDetail> = [];
  manualChargeOffLoans: boolean = false;
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  submitted: boolean = false;
  isDirty:boolean = false;
  DATE_FORMAT = ConstantConfig.DATE_FORMAT;
  isLoanActive:boolean=false;
  appform!: UntypedFormGroup;
  constructor(
    private httpService: HttpService,
    private msgService: MessageService,
    private permissionService: PermissionService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private broadCastService:BroadcastService,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
    this.manualChargeOffLoans = this.permissionService.isSpecialPermission('manual_charge_off');
  }

  ngOnInit(): void {
    this.appform = new UntypedFormGroup({
      loanId: new UntypedFormControl(null, [Validators.required]),
      employee_initials: new UntypedFormControl(null, [Validators.required]),
       pin: new UntypedFormControl(null, [Validators.required]),
     });
    this.isLoanActive=this.loanDetail?.status=='ACTIVE'? true:false;
  }



  verifyPin()
  {
    if(this.appform.valid)
      {
        const params = this.appform.getRawValue();
        const url = `${PathConfig.VERIFY_PIN}`;
        this.httpService.post(url, params).subscribe({
          next : (res) => {


            if(res?.message!='User not found' && res?.message!='User validation failed')
            {
          this.msgService.showSuccess(res?.message);
          this.manualChargeOffLoans=true;
          // this.routeAccount();
          this.submit();
            }
          else
          {
          this.msgService.showError(res?.message);
          }
            this.submitted = false;
            this.isDirty = false;
            this.appform.reset();


          },
          error : (err) => {
            const msgList = Utility.getErrorMessage(err.error);
            msgList.forEach((msg) => this.msgService.showError(msg));
          }
        });

      }


  }





  /**
   * Get Approval Modal
   * @param content
   */
  openGetApprovalModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'sm', windowClass:'draggable-container',
    backdrop:false });
  }

  /**
   * charge off loan Account
   */

  submit(){
    ///loans/manual_charge_off/{loan_id}
    const url =`${PathConfig.LOANS_MANUAL_CHARGE_OFF}/${this.loanID}` ;
    this.httpService.get(url).subscribe((response)=>{
      this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
      this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
      this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);
      this.msgService.showSuccess(response?.message);
      this.submitted = false;
        this.isDirty = false;
       },(err)=>{
         this.msgService.showError(err.error.message);
         this.submitted = false;
       });
       this.modalService.dismissAll();
  }

}
