<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
  <div class="draggable-modal-content " >
<ng-container>
    <form #paymentForm = "ngForm" [formGroup]="takePaymentForm" novalidate>
        <div class="modal-header align-items-center cursor-pointer" cdkDragHandle>
          <h6 *ngIf="loanTransactionDetails?.payment_info_code?.slug!='WAIVE'" class="modal-title modal-title-success">{{editMode ? 'Edit' : ''}} {{'Take Payment'|translate}}</h6>
          <h6 *ngIf="loanTransactionDetails?.payment_info_code?.slug=='WAIVE'" class="modal-title modal-title-success">{{'Edit Fee Balances'|translate}}</h6>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="lms-module-card">
            <div class="card-body">
              <div class="card-form-content card-form-success">
                <div class="row mb-3">
                  <div class="col-lg-7">
                    <div *ngIf="loanTransactionDetails?.payment_info_code?.code_type!='NON-CASH-SYSTEM'" class="row gutters-5">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label field-required">{{'Payment Code'|translate}}</label>
                        <val-errors controlName="payment_info_code_id">
                          <ng-template valError="required">{{'Please select payment code'|translate}}</ng-template>
                        </val-errors>
                      </div>
                      <div class="form-group col-lg-7">
                        <select name="payment_info_code_id"
                        formControlName="payment_info_code_id" class="form-control form-control-sm"
                        (change)="customValidators()">
                          <option [ngValue]="null">{{'-Select-'|translate}}</option>
                          <option *ngFor="let item of paymentCodeList"   [ngValue]="item.id">
                            {{item.code}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row gutters-5">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label field-required">{{'Effective Date'|translate}}</label>
                        <val-errors controlName="payment_effective_date">
                            <ng-template valError="required">{{'Please enter a date'|translate}}</ng-template>
                          </val-errors>
                      </div>
                      <div class="form-group col-lg-7">
                        <ng-container *ngIf="loanTransactionDetails?.payment_info_code?.slug=='WAIVE'">
                          <input type="text"
                          [readOnly]="loanTransactionDetails?.payment_info_code?.slug=='WAIVE'"
                          formControlName="payment_effective_date"
                          name="payment_effective_date"
                          class="form-control form-control-sm" [value]=""
                          />
                        </ng-container>
                        <ng-container *ngIf="loanTransactionDetails?.payment_info_code?.slug!='WAIVE'">
                          <app-date-picker name="payment_effective_date"
                          #efdate
                          (dateSelected)="getDif(efdate.value)"
                          formControlName="payment_effective_date"></app-date-picker>
                        </ng-container>
                      </div>
                    </div>
                    <div *ngIf="selectedPaymentCode?.code_type=='CASH'" class="row gutters-5">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label field-required">{{'Payment Method'|translate}}</label>
                        <val-errors controlName="payment_method_id">
                            <ng-template valError="required">{{'Please select payment method'|translate}}</ng-template>
                          </val-errors>
                      </div>
                      <div class="form-group col-lg-7">
                        <select name="payment_method_id" formControlName="payment_method_id" class="form-control form-control-sm">
                          <option [ngValue]="null">{{'-Select-'|translate}}</option>
                          <option *ngFor="let item of paymentMethodList" [ngValue]="item.id">
                            {{item.source}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row gutters-5">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label field-required">{{'Amount'|translate}}</label>

                      </div>
                      <div class="form-group col-lg-7">
                        <div class="currency-wrap">
                          <span class="currency-code">$</span>
                          <input type="number" (blur)="checkMainAmount()"
                          formControlName="payment_amount"
                          name="payment_amount" placeholder="0.00"
                          class="form-control form-control-sm" [value]=""
                          onfocus="select()"
                          />
                        </div>

                      </div>
                      <val-errors controlName="payment_amount">
                        <ng-template valError="required">{{'Please Enter Amount'|translate}}</ng-template>
                    </val-errors>
                    <val-errors controlName="payment_amount">
                      <ng-template valError="currencyRange">{{'Amount must be between - 30000 and 30000'|translate}}</ng-template>
                      <!-- <ng-template *ngIf="loanTransactionDetails?.payment_info_code?.slug=='WAIVE'" valError="currencyRange">Amount must be between -30000 and 30000</ng-template>
                      <ng-template *ngIf="loanTransactionDetails?.payment_info_code?.slug!='WAIVE'" valError="currencyRange">Amount must be between 0 and 30000</ng-template> -->
                    </val-errors>
                    </div>
                    <!-- <div class="row gutters-5"  *ngIf="this.loanDetail?.status!='CHARGED-OFF'"> -->
                      <div class="row gutters-5" *ngIf="loanTransactionDetails?.payment_info_code?.slug!='WAIVE'">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label field-required"> {{'Posting Method'|translate}} </label>
                        <val-errors controlName="posting_method">
                            <ng-template valError="required">{{'Please select posting method'|translate}}</ng-template>
                        </val-errors>
                      </div>
                      <div class="form-group col-lg-7">
                        <select name="posting_method" formControlName="posting_method" class="form-control form-control-sm">
                          <option [ngValue]="null">{{'-Select-'|translate}}</option>
                          <option value="ROLL_DUE_DATE">{{'Rolls Due Date'|translate}} </option>
                          <option value="ROLL_UNTIL_CURRENT">{{'Rolls Until Current'|translate}} </option>
                          <option value="DO_NOT_ROLL_DUE_DATE">{{"Doesn't Roll Due Date"|translate}} </option>
                          <!-- <option value="Payment Doesn't Roll Due Date">Payment Doesn't Roll Due Date</option> -->
                        </select>
                      </div>
                    </div>
                    <!------------------------Pmt Received Method -------------------------------->
                    <div class="row gutters-5" *ngIf="loanTransactionDetails?.payment_info_code?.slug!='WAIVE'">
                      <div class="form-group col-lg-5">
                        <label class="form-group-label">{{'Pmt Received Method'|translate}} </label>
                      </div>
                      <div class="form-group col-lg-7">
                        <select name="payment_received_method" formControlName="payment_received_method" class="form-control form-control-sm">
                          <option [ngValue]="null">{{'-Select-'|translate}}</option>
                          <option value="RECEIVED_IN_MAIL">{{'Received In Mail'|translate}} </option>
                          <option value="MADE_IN_PERSON">{{'Made In Person'|translate}} </option>

                          <!-- <option value="Payment Doesn't Roll Due Date">Payment Doesn't Roll Due Date</option> -->
                        </select>
                      </div>
                    </div>
                    <!------------------------Pmt Received Method End-------------------------------->
                  </div>
                </div>
                <div class="maunal-waterfall-breakdown" *ngIf="isManualTakePayment && this.isManualBreakdownAllowed()">
                  <div class="maunal-waterfall-breakdown-grid maunal-payment-breakdown-grid-header maunal-waterfall-breakdown-header">
                    <div class="maunal-waterfall-breakdown-item font-weight-bold">
                      {{'Input Breakdown'|translate}}
                    </div>
                    <div class="maunal-waterfall-breakdown-item font-weight-bold">
                      {{'Balance Before'|translate}}
                    </div>
                    <div class="maunal-waterfall-breakdown-item"></div>
                    <div class="maunal-waterfall-breakdown-item font-weight-bold">
                      {{'Balance After'|translate}}
                    </div>
                  </div>
                  <div class="maunal-waterfall-breakdown-body">
                    <!-- Item 1 -->
                    <div  *ngIf="loanTransactionDetails?.payment_info_code?.slug!='WAIVE'" class="maunal-waterfall-breakdown-grid maunal-waterfall-breakdown-grid-2 align-items-center">
                      <div class="maunal-waterfall-breakdown-item">
                        <div class="row gutters-5">
                          <div class="form-group col-lg-5">
                            <label class="form-group-label">{{'Principal'|translate}}</label>
                          </div>
                          <div class="form-group col-lg-7">
                            <div class="currency-wrap">
                              <span class="currency-code">$</span>
                            <input [readonly]="loanTransactionDetails?.payment_info_code?.slug=='WAIVE'" type="number" name="principal_payment" formControlName="principal_payment"
                            (blur)="calculateBalance($event)" class="form-control form-control-sm"
                            placeholder="0.00" [value]=""
                            onfocus="select()" />
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="maunal-waterfall-breakdown-item">
                            <div class="row gutters-5">
                                <div class="form-group col-lg-4">
                                    <label class="form-group-label">
                                      {{(previousTransaction?.remaining_principal_balance || '0.00' ) | currency}}
                                    </label>
                                </div>
                                <div class="form-group col-lg-2 text-center">
                                    <label class="form-group-label">
                                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                                    </label>
                                </div>
                                <div class="form-group col-lg-4 padL40">
                                    <label class="form-group-label">
                                      {{(balanceAfter?.principal_payment || '0.00' ) | currency}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Item 2 -->
                    <div  *ngIf="loanTransactionDetails?.payment_info_code?.slug!='WAIVE'" class="maunal-waterfall-breakdown-grid maunal-waterfall-breakdown-grid-2 align-items-center">
                      <div class="maunal-waterfall-breakdown-item">
                        <div class="row gutters-5">
                          <div class="form-group col-lg-5">
                            <label class="form-group-label">{{'Interest'|translate}} </label>
                          </div>
                          <div class="form-group col-lg-7">
                            <div class="currency-wrap">
                              <span class="currency-code">$</span>
                            <input [readonly]="loanTransactionDetails?.payment_info_code?.slug=='WAIVE'" type="number" name="interest_payment" formControlName="interest_payment"
                            (blur)="calculateBalance($event)" class="form-control form-control-sm"
                            placeholder="0.00" [value]=""
                            onfocus="select()" />
                          </div>
                          </div>
                        </div>
                      </div>
                        <div class="maunal-waterfall-breakdown-item">
                            <div class="row gutters-5">
                                <div class="form-group col-lg-4">
                                    <label class="form-group-label">
                                      {{(previousTransaction?.remaining_interest_balance || '0.00' ) | currency}}
                                    </label>
                                </div>
                                <div class="form-group col-lg-2 text-center">
                                    <label class="form-group-label">
                                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                                    </label>
                                </div>
                                <div class="form-group col-lg-4 padL40">
                                    <label class="form-group-label">
                                      {{(balanceAfter?.interest_payment || '0.00' ) | currency}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Item 3 -->
                    <div class="maunal-waterfall-breakdown-grid maunal-waterfall-breakdown-grid-2 align-items-center">
                      <div class="maunal-waterfall-breakdown-item">
                        <div class="row gutters-5">
                          <div class="form-group col-lg-5">
                            <label class="form-group-label">{{'Late Fees'|translate}}</label>
                          </div>
                          <div class="form-group col-lg-7">
                            <div class="currency-wrap">
                              <span class="currency-code">$</span>
                            <input type="number" name="late_fees_payment" formControlName="late_fees_payment"
                            (blur)="calculateBalance($event)" class="form-control form-control-sm"
                            placeholder="0.00"  [value]=""
                            onfocus="select()" />
                          </div>
                          </div>
                        </div>
                      </div>
                        <div class="maunal-waterfall-breakdown-item">
                            <div class="row gutters-5">
                                <div class="form-group col-lg-4">
                                    <label class="form-group-label">
                                      {{(previousTransaction?.remaining_late_fees_balance || '0.00' ) | currency}}
                                    </label>
                                </div>
                                <div class="form-group col-lg-2 text-center">
                                    <label class="form-group-label">
                                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                                    </label>
                                </div>
                                <div class="form-group col-lg-4 padL40">
                                    <label class="form-group-label">
                                      {{(balanceAfter?.late_fees_payment || '0.00')  | currency  }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <val-errors controlName="late_fees_payment">
                          <ng-template valError="currencyRange">{{'Late Fees must be between - 10000 and 10000'|translate}}</ng-template>
                        </val-errors>
                    </div>
                    <!-- Item 4 -->
                    <div class="maunal-waterfall-breakdown-grid maunal-waterfall-breakdown-grid-2 align-items-center">
                      <div class="maunal-waterfall-breakdown-item">
                        <div class="row gutters-5">
                          <div class="form-group col-lg-5">
                            <label class="form-group-label">{{'NSF Fees'|translate}}</label>
                          </div>
                          <div class="form-group col-lg-7">
                            <div class="currency-wrap">
                              <span class="currency-code">$</span>
                            <input type="number" name="nsf_fees_payment" formControlName="nsf_fees_payment"
                            (blur)="calculateBalance($event)" class="form-control form-control-sm"
                            placeholder="0.00"[value]=""
                            onfocus="select()" />
                          </div>
                          </div>
                        </div>
                      </div>
                        <div class="maunal-waterfall-breakdown-item">
                            <div class="row gutters-5">
                                <div class="form-group col-lg-4">
                                    <label class="form-group-label">
                                      {{(previousTransaction?.remaining_nsf_fees_balance|| '0.00' ) | currency}}
                                    </label>
                                </div>
                                <div class="form-group col-lg-2 text-center">
                                    <label class="form-group-label">
                                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                                    </label>
                                </div>
                                <div class="form-group col-lg-4 padL40">
                                    <label class="form-group-label">
                                      {{(balanceAfter?.nsf_fees_payment|| '0.00' ) | currency}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <val-errors controlName="nsf_fees_payment">
                          <ng-template valError="currencyRange">{{'NSF Fees must be between - 10000 and 10000'|translate}}</ng-template>
                        </val-errors>
                    </div>
                    <!-- Item 5 -->
                    <div class="maunal-waterfall-breakdown-grid maunal-waterfall-breakdown-grid-2 align-items-center">
                      <div class="maunal-waterfall-breakdown-item">
                        <div class="row gutters-5">
                          <div class="form-group col-lg-5">
                            <label class="form-group-label">{{'Other Fees'|translate}}</label>
                          </div>
                          <div class="form-group col-lg-7">
                            <div class="currency-wrap">
                              <span class="currency-code">$</span>
                            <input type="number" name="other_fees_payment" formControlName="other_fees_payment"
                            (blur)="calculateBalance($event);"
                            class="form-control form-control-sm" placeholder="0.00" [value]=""
                            onfocus="select()" />
                          </div>
                          </div>
                        </div>
                      </div>
                        <div class="maunal-waterfall-breakdown-item">
                            <div class="row gutters-5">
                                <div class="form-group col-lg-4">
                                    <label class="form-group-label">
                                      {{(previousTransaction?.remaining_other_fees_balance|| '0.00' ) | currency}}
                                    </label>
                                </div>
                                <div class="form-group col-lg-2 text-center">
                                    <label class="form-group-label">
                                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                                    </label>
                                </div>
                                <div class="form-group col-lg-4 padL40">
                                    <label class="form-group-label">
                                      {{(balanceAfter?.other_fees_payment|| '0.00' ) | currency}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <val-errors controlName="other_fees_payment">
                          <ng-template valError="currencyRange">{{'Other Fees must be between - 10000 and 10000'|translate}}</ng-template>
                        </val-errors>
                    </div>
                    <!-- Item 6 -->
                    <div class="maunal-waterfall-breakdown-grid maunal-waterfall-breakdown-grid-2 align-items-center">
                      <div class="maunal-waterfall-breakdown-item">
                        <div class="row gutters-5">
                          <div class="form-group col-lg-5">
                            <label class="form-group-label">Down Payment</label>
                          </div>
                          <div class="form-group col-lg-7">
                            <div class="currency-wrap">
                              <span class="currency-code">$</span>
                            <input type="number" name="down_payment" formControlName="down_payment"
                            (blur)="calculateBalance($event)" class="form-control form-control-sm"
                            placeholder="0.00" [value]="" 
                            onfocus="select()" />
                          </div>
                          </div>
                        </div>
                      </div>
                        <div class="maunal-waterfall-breakdown-item">
                            <div class="row gutters-5">
                                <div class="form-group col-lg-4">
                                    <label class="form-group-label">
                                      {{(previousTransaction?.remaining_down_payment_balance || '0.00' )| currency }}
                                    </label>
                                </div>
                                <div class="form-group col-lg-2 text-center">
                                    <label class="form-group-label">
                                        <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                                        <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                                    </label>
                                </div>
                                <div class="form-group col-lg-4 padL40">
                                    <label class="form-group-label">
                                      {{(balanceAfter?.down_payment|| '0.00' ) | currency}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <val-errors controlName="down_payment">
                          <ng-template valError="currencyRange">{{'Down Payment must be between - 10000 and 10000'|translate}}</ng-template>
                        </val-errors>
                    </div>
                    <!-- Item 7 -->
                    <div class="maunal-waterfall-breakdown-grid maunal-waterfall-breakdown-grid-2 align-items-center">
                      <div class="maunal-waterfall-breakdown-item">
                        <div class="row gutters-5">
                          <div class="form-group col-lg-5">
                            <label class="form-group-label">{{'Total'|translate}}</label>
                          </div>
                          <div class="form-group col-lg-7">
                            <!-- text-danger -->
                            <div class="currency-wrap">
                              <span class="currency-code">$</span>
                            <input type="number" name="total_payment" formControlName="total_payment"
                            #totalFee class="form-control form-control-sm"
                            [ngClass]="{' text-danger' : isOverAmount}"  placeholder="0.00" readonly />
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="font-size-14 text-danger" *ngIf="isOverAmount">
                      {{'Breakdown total must match the payment amount.'|translate}}
                    </div>
                  </div>
                  <div *ngIf="loanTransactionDetails?.payment_info_code?.code_type!='NON-CASH-SYSTEM'">{{'Or'|translate}} <button type="button" class="btn btn-link px-0 text-underline" (click)="toggleTakeMethodType()">{{'Use Default Waterfall'|translate}}</button></div>
                </div>
                <button *ngIf="this.isManualBreakdownAllowed() && !isManualTakePayment && loanTransactionDetails?.payment_info_code?.code_type!='NON-CASH-SYSTEM'" type="button" class="btn btn-link px-0 text-underline" (click)="toggleTakeMethodType()">{{'Manual Waterfall Breakdown'|translate}}</button>
                <div class="mt-3 form-group">
                  <label>{{'Reference Note'|translate}}</label>
                  <input type="text" class="form-control form-control-sm" name="reference_note" formControlName="reference_note" value="Check #123" />
                  <val-errors controlName="reference_note">
                    <ng-template valError="pattern"
                    >{{'Reference Note is not well formed'|translate}}</ng-template>
                    <ng-template valError="maxlength">{{'Reference Note cannot be longer than 200 characters'|translate}}</ng-template>
                    <ng-template valError="minlength">{{'Reference Note cannot be less than 4 characters'|translate}}</ng-template>
                  </val-errors>
                </div>
                <div class="mt-3 form-group" *ngIf="editMode">
                  <div class="form-group">
                    <label class="form-group-label field-required  hide-before-border">{{'Reason for Edit'|translate}}</label>

                  </div>
                  <div class="mt-3 form-group">
                    <input type="text"  class="form-control form-control-sm"
                    name="payment_edit_reason" formControlName="payment_edit_reason" [value]="" />
                    <val-errors controlName="payment_edit_reason">
                      <ng-template valError="required">{{'Please Enter Reason for Edit'|translate}}</ng-template>
                      <ng-template valError="pattern">{{'Reason for Edit is not well formed'|translate}}</ng-template>
                      <ng-template valError="maxlength">{{'Reason for Edit cannot be longer than 100 characters'|translate}}</ng-template>
                      <ng-template valError="minlength">{{'Reason for Edit cannot be less than 4 characters'|translate}}</ng-template>
                    </val-errors>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between pt-0 cursor-pointer" cdkDragHandle>
          <div>
            <span class="d-block font-size-14 lms-text-light-pink" *ngIf="disablePastDate">
              {{'Effective date cannot be prior to the import date:'|translate}} {{loanTransactionDetails?.import_transaction_date | date: 'shortDate' }}
            </span>
            <span class="d-block font-size-14 lms-text-light-pink" *ngIf="isFutureDate">
              {{'Effective date cannot be in the future.'|translate}}
            </span>
          </div>
          <div>
            <button type="button" [disabled]="isFutureDate||disablePastDate" class="btn btn-success-light btn-150"  (click)="onTakePaymentSubmit()">
              {{'Apply'|translate}}
            </button>
          </div>
        </div>
      </form>
</ng-container>
</div>
</div>
