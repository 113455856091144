import { Component,  Input, OnInit} from '@angular/core';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-caller',
  templateUrl: './caller.component.html',
  styleUrls: ['./caller.component.scss']
})
export class CallerComponent implements OnInit {

  @Input() loanDetail!: any;
  @Input() maskOff: boolean=false;
  @Input() dailing_no?: any;
  call! : UntypedFormGroup;
  isDirty:boolean = false;
  dailing_number?: any;
  US_PHONE_MASKING = ConstantConfig.US_PHONE_MASKING;

  constructor(
    public activeModal: NgbActiveModal,
    private translateService:TranslateService
  ) {
    
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en'); }

  ngOnInit(): void {
    this.call = new UntypedFormGroup({
      dailing_number: new UntypedFormControl('', [Validators.required]),
  })
    this.call.valueChanges.subscribe((e) => (this.isDirty = true));
    this.getdailingnumber();
  }
  getdailingnumber(){
    this.dailing_no !=null? this.dailing_no : '';
  }

}
