import { Component, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexMarkers,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexStroke,
  ChartComponent,
  ApexChart,
  ApexGrid,
  ApexLegend,
} from 'ng-apexcharts';

import {
  BroadcastService,
  HttpService,
  LocalStorageService,
  MessageService,
  PermissionService,
  UserService,
  ThemeService,
  LoaderService
} from 'src/app/core';

import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { PathConfig } from 'src/app/core/config/path.config';
import { Utility } from 'src/app/core/utils/utility';
import { ClientDetailService } from 'src/app/core/services/client-detail-service';

import _ from 'lodash';
import moment from 'moment';
import { LoanActivityNote } from 'src/app/shared/models/loan/loan.activity.model';
import { RouteAccountsComponent } from './route-accounts/route-accounts.component';
import { WaiveFeeComponent } from './waive-fee/waive-fee.component';
import { Subject, forkJoin } from 'rxjs';
import { ReamortizeLoanComponent } from './reamortize-loan/reamortize-loan.component';
import { ProcessExtensionComponent } from './process-extension/process-extension.component';
import { RepoReviewComponent } from './repo-review/repo-review.component';
import { KickabckReviewComponent } from './kickabck-review/kickabck-review.component';
import { LoanDetail } from 'src/app/shared/models/loan/loan.detail.model';
import { NewNoteComponent } from './new-note/new-note.component';
import { DueDateComponent } from './due-date/due-date.component';
import { LoanInProgressModalComponent } from './loan-in-progress-modal/loan-in-progress-modal.component';
import { Subscription } from 'rxjs';
import { QueueDetailServiceService } from './queue-detail-service.service';
import { ManualChargeOffComponent } from './manual-charge-off/manual-charge-off.component';
import { StartStopInterestAccrualComponent } from './start-stop-interest-accrual/start-stop-interest-accrual.component';
import { TakePmtComponent } from './take-pmt/take-pmt.component';
import { LoadingBarModalComponent } from 'src/app/shared/components/loading-bar-modal/loading-bar-modal.component';
import { StartStopFeeAccrualsComponent } from './start-stop-fee-accruals/start-stop-fee-accruals.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { takeUntil } from 'rxjs/operators';
import { ClientUtils } from 'src/app/core/utils/clientUtil';
import { TitleCasePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { MultilingualService } from 'src/app/core/modules/header/multilingual.service';
export type activityChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels|null;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  grid?: ApexGrid|null;
  colors: any;
  toolbar: any;
  legend: ApexLegend;
};

export type chart2options = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels?: ApexDataLabels |null;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  grid?: ApexGrid|null;
  colors: any;
  toolbar: any;
  legend: ApexLegend;
};

@Component({
  selector: 'app-queue-detail',
  templateUrl: './queue-detail.component.html',
  styleUrls: ['./queue-detail.component.scss'],
})
export class QueueDetailComponent implements AfterViewInit {
  @ViewChild('chart') chart!: ChartComponent;
  public theme: string = "";
  loanID!: Number;
  activityTypes: Array<any> = [
    { id: 'PHONE', label: 'PHONE' },
    { id: 'INBOUNDCALL', label: 'INBOUNDCALL' },
    { id: 'OUTBOUNDCALL', label: 'OUTBOUNDCALL' },
    { id: 'SMS', label: 'SMS' },
    { id: 'EMAIL', label: 'EMAIL' },
  ];
  isAcivityPTPActive: boolean = false;
  sectionTitle: string = '';
  addActivityForm!: UntypedFormGroup;
  addActivityFormSubmit: boolean = false;
  loanDetail: any;
  assignmentHistory: any;
  US_PHONE_MASKING = ConstantConfig.US_PHONE_MASKING;
  activityChartOptions?: Partial<any>;
  isActivityChartRender: boolean = false;
  chartNetBalAtRisk: Partial<any> = {};
  chartNetBalAtRiskRender: boolean = false;
  chart2options?: Partial<any> ;
  DATE_FORMAT_MMDDYYYY = ConstantConfig.DATE_FORMAT_MMDDYYYY;
  DATE_FORMAT_MMDDYY = ConstantConfig.DATE_FORMAT_MMDDYY;
  clientInfo = {
    name: '',
    // type: '',
  };

  activityPageCnter: number = 1;
  activityTotalSize: number = 0;
  currentActivity!: LoanActivityNote;
  activityList: Array<LoanActivityNote> = [];
  activityChartDurInDays: number = 30;
  avgChartOptions!: Partial<any>;
  isAvgChartRenderd: boolean = false;
  isAssestRecoverySection: boolean = false;
  queueType: string = '';
  sortBy: string = '';
  orderBy: string = '';
  pageLimit: number = 0;
  currentPage: number = 0;
  queueList: Array<any> = [];
  previousTransaction: any;
  isFutureDate:boolean=false;
  approved_per:any;
  RiskGradePermistion:boolean=false;
  CollectiveGradePermistion:boolean=false;
  NBARPermistion:boolean=true;
  LTVPermistion:boolean=true;
  PBARPermistion:boolean=true;
  OddsPermistion:boolean=true;
  TakePaymentPermission:boolean=false;
  activityNotePermistion:boolean=false;
  isReamortizePermission :boolean=false;
  isExtPermission:boolean=false;
  waivePermistion:boolean=false;
  isRepoPermission:boolean=false;
  isRoutedPermission:boolean=false;
  isDueDatePermission:boolean=false;
  isToolEnable:boolean=true;
  submitRepoReviewStatus:any=-2;
  lastRepoReviewDate:string='';
  loanDetailResponse:any;
  eventSubscription: Subscription[] = [];
  amount_to_roll:number=0;
  sus:number=0;
  sus_temp2:number=0;
  sus_temp1:number=0;
  plus_sus:number=0;
  min_value:number=0;
  t:number=0;
  totalamountdue:number=0;
  appform!: UntypedFormGroup;
  amount_due:number=0;
  total_amount_due:number=0;
  sus_value:number=0;
  amt_due_value:number=0;
  t1_sus:number=0;
  t2_sus:number=0;
  repo_sub_status:any;
  is_next_Disable:boolean=false;
  take_payment_url:string='';
  loanInProgressStatus : boolean = false;
  queue_root_url:string='';
  isloan_informationCanAccess:boolean=true;
  isactivity_notesCanAccess:boolean=true;
  isnotification_logsCanAccess:boolean=true;
  isaccount_historyCanAccess:boolean=true;
  isaccount_homeCanAccess:boolean=true;
  istransactionsCanAccess:boolean=true;
  isapplicantCanAccess:boolean=true;
  isapplicantViewEdit:boolean=true;

  isasset_recoveryCanAccess: boolean = true;
  timeLinePermistion: boolean = true;
  viewNextAction: boolean = true;
  manualChargeOffLoans: boolean = true;

  selecteduser: any;
  selectedMainStatus: any;
  selectedPortfolio: any;
  selected_sub_status: any;
  selectedpriority: any;
  pri: any;
  show_avg_odds_of_roll = '00.0';
  modalRef: any;
  processingTransactionId: any;
  selectedChart: any = 'act';

  colorArr: any = ['#00B050', '#3489D8', '#FF0000', '#FF5B5B', '#FFFF00', '#0899EC', '#E4CAF7', '#FFC000', '#00B050', '#A90D99', '#F6D673', '#01E768', '#D8C2E8',
    '#00B050', '#3489D8', '#FF0000', '#FF5B5B', '#FFFF00', '#0899EC', '#E4CAF7', '#FFC000', '#00B050', '#A90D99', '#F6D673', '#01E768', '#D8C2E8',
    '#00B050', '#3489D8', '#FF0000', '#FF5B5B', '#FFFF00', '#0899EC', '#E4CAF7', '#FFC000', '#00B050', '#A90D99', '#F6D673', '#01E768', '#D8C2E8'];

  ptpMarkers: Array<any> = [{ 'id': 0, 'lable': 'IMPORTED', 'name': 'PTP', 'color': '#ffe599' }, { 'id': 0, 'lable': 'PENDING', 'name': 'PTP', 'color': '#ffe599' }, { 'id': 1, 'lable': 'BROKEN', 'name': 'BPTP', 'color': '#e06666' }, { 'id': 2, 'lable': 'SUCCESS', 'name': 'SPTP', 'color': '#39761d' }]

  current_fee_balance_paid:number=0;
  showProgressBar: boolean=true;
  // ngUnsubscribe$: Subject<void> = new Subject<void>();
  destroy$ = new Subject<void>();
  showClientBanner: boolean = false;
  maintenanceStartWarning: boolean = false;
  isMaintenancModeOn: boolean = false;
  selectedLanguage: any;
  isLoanApiPatching:boolean = false;
  showFullNote: boolean = false;

  canAccessSideNotes:boolean=true;


  constructor(
    private userService: UserService,
    private themeService: ThemeService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private httpService: HttpService,
    private msgService: MessageService,
    private broadCastService: BroadcastService,
    private clientDetailService: ClientDetailService,
    private permissionService: PermissionService,
    public loaderService: LoaderService,
    private queueDetailServiceService: QueueDetailServiceService,
    private clipboard: Clipboard,
    private cdr: ChangeDetectorRef,
    private translateService:TranslateService,
     private multilingualService:MultilingualService
  ) {
    this.showClientBanner = ClientUtils.isLoginAsClient(this.clientDetailService, this.userService);
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
    this.isloan_informationCanAccess= this.permissionService.isAccess('loan_information');
    this.isactivity_notesCanAccess= this.permissionService.isAccess('activity_notes');
    this.isnotification_logsCanAccess= this.permissionService.isAccess('notification_logs');
    this.isaccount_historyCanAccess= this.permissionService.isAccess('account_history');
    this.isaccount_homeCanAccess= this.permissionService.isAccess('account_home');
    this.istransactionsCanAccess= this.permissionService.isAccess('transactions');
    this.isapplicantCanAccess= this.permissionService.isAccess('applicant_information');
   /********************* */
   this.isapplicantCanAccess= this.permissionService.isAccess('applicant');
   this.isapplicantCanAccess= this.permissionService.isAccess('co_signer');
   this.isapplicantCanAccess= this.permissionService.isAccess('recipient');
   this.isapplicantCanAccess= this.permissionService.isAccess('references');
  /********************** */
    this.isasset_recoveryCanAccess= this.permissionService.isAccess('asset_recovery');

    this.canAccessSideNotes= this.permissionService.isAccess('side_notes');








    this.RiskGradePermistion = this.permissionService.isSpecialPermission('risk_grade');
    this.CollectiveGradePermistion = this.permissionService.isSpecialPermission('collectability_grade');

    this.timeLinePermistion = this.permissionService.isSpecialPermission('can_view_activity_timeline');

    this.NBARPermistion = this.permissionService.isSpecialPermission('NBAR_chart');
    this.PBARPermistion = this.permissionService.isSpecialPermission('PBAR_chart');
    this.LTVPermistion = this.permissionService.isSpecialPermission('LTV_chart');
    this.OddsPermistion = this.permissionService.isSpecialPermission('Roll_chart');
    this.TakePaymentPermission = this.permissionService.isSpecialPermission('post_payment');
    this.activityNotePermistion = this.permissionService.isSpecialPermission('activity_note');
    this.isReamortizePermission = this.permissionService.isSpecialPermission('reamortize');
    this.isExtPermission = this.permissionService.isSpecialPermission('process_extensions');
    this.waivePermistion = this.permissionService.isSpecialPermission('waive_fees');
    this.isRepoPermission = this.permissionService.isSpecialPermission('submit_repo_review');
    this.isRoutedPermission = this.permissionService.isSpecialPermission('route_account');
    this.isDueDatePermission = this.permissionService.isSpecialPermission('change_due_date');
    this.viewNextAction = this.permissionService.isSpecialPermission('view_next_action');
    this.manualChargeOffLoans = this.permissionService.isSpecialPermission('manual_charge_off');
    if (this.isReamortizePermission == false && this.isExtPermission == false && this.waivePermistion == false && this.isRepoPermission == false && this.isRoutedPermission == false && this.isDueDatePermission == false)
      this.isToolEnable = false;


    //this.OddsPermistion=false;

   // this.RiskGradePermistion=true;





    this.clientInfo.name = this.clientDetailService.getClientInfo('name');
    // this.clientInfo.type =
    //   this.clientDetailService.getClientInfo('is_default') == 'NO'
    //     ? 'Dealership'
    //     : 'Client';

    const routeEvents = this.activatedRoute.queryParams
      .subscribe(params => {

        this.queueType = params['qtype'] == null ? "" : params['qtype'];
        this.sortBy = params['sortby'] == null ? "" : params['sortby'];
        this.orderBy = params['orderby'] == null ? "" : params['orderby'];
        this.pageLimit = params['limit'] == null ? "" : params['limit'];
        this.currentPage = params['page'] == null ? "" : params['page'];

      });
    this.eventSubscription.push(routeEvents);


    const routeEvents1 = this.broadCastService
      .on(MsgConstantConfig.QUEUE_DETAIL_SEC_HEADING)
      .subscribe((label: any) => {
        this.sectionTitle = label;
        this.isAssestRecoverySection = label == 'Asset Recovery' ? true : false;

      });
    this.eventSubscription.push(routeEvents1);

    const routeEvents2 = this.broadCastService
      .on(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA)
      .subscribe(() => {
        this.loanID = Number(this.route.url.split('/')[5]);
        if (this.loanID) {

          this.getLoanDetailByID(this.loanID);
        }
      });
    this.eventSubscription.push(routeEvents2);

    this.broadCastService.broadcast(
      MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA
    );



      const routeEvent7 = route.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.broadCastService.broadcast(
            MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA
          );
        }
        this.compareWithPstTime();
      });

      this.eventSubscription.push(routeEvent7);

    const routeEvents3=   this.broadCastService.on(MsgConstantConfig.REFRESH_ACTIVITY).subscribe((id) => {
      if(this.loanID){
        this.getLTVChartData(this.loanID);
        if (this.selectedChart === 'act')
          this.getActivity();
      }
    });
    this.eventSubscription.push(routeEvents3);


    //activity form controls
    this.addActivityForm = new UntypedFormGroup({
      activity_type_id: new UntypedFormControl('', [Validators.required]),
      note: new UntypedFormControl('', [Validators.required]),
      ptp_date: new UntypedFormControl('', []),
      ptp_amount: new UntypedFormControl('', []),
    });

    this.addActivityForm.controls.activity_type_id.valueChanges.subscribe(
      (val) => {
        const selectedActivity = this.activityTypes.find(
          (item) => item.id == val
        );
        this.isAcivityPTPActive = false;
        if (selectedActivity) {
          this.isAcivityPTPActive = selectedActivity?.is_input_ptp == 'YES';
        }
      }
    );
    this.theme = this.userService.getUserInfo('theme_mode') ? this.userService.getUserInfo('theme_mode').toLowerCase() : '';

    // listen event of theme change
    const routeEvents5=  this.themeService.theme$.subscribe((theme)=>{
      this.theme  = this.userService.getUserInfo('theme_mode') ? this.userService.getUserInfo('theme_mode').toLowerCase() : '';
      if(this.loanID ){
        if(this.loanDetail && !this.isLoanApiPatching){
          this.createNBARChart(this.loanDetail);
          this.getLTVChartData(this.loanID);
        }
        if(this.selectedChart==='act')
          this.getActivity();
        if(this.selectedChart==='pmts'){
          this.getPmtChartData(this.loanID);
        }
        if (this.selectedChart==='buckets'){
          this.getBucketChartData(this.loanID);
        }
      }
    });
    this.eventSubscription.push(routeEvents5);


    this.appform = new UntypedFormGroup({

      employee_initials: new UntypedFormControl(null, [Validators.required]),
       pin: new UntypedFormControl(null, [Validators.required]),
     });




    //  const routeEvents6=   this.route.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.activityPageCnter=1;
    //   }
    // });
    // this.eventSubscription.push(routeEvents6);

  }

  ngOnInit() {
    this.route.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Close loading bar when navigation starts
        this.closeLoadingbar();
        window.scrollTo(0, 0); // Scrolls to the top of the page
      }
    });
    this.compareWithPstTime();
    this.transactionLoadingBarStarter();
    // Listen for browser back button click
    window.addEventListener('popstate', () => {
      this.showProgressBar = false;
      // Close loading bar when the back button is clicked
      this.closeLoadingbar();
      this.reloadWithoutWhiteScreen();
    });
    //   if (event instanceof NavigationStart) {
    //     // Close loading bar when navigation starts
    //     this.closeLoadingbar();
    //   }
    // });
    // this.transactionLoadingBarStarter();
    // // Listen for browser back button click
    // window.addEventListener('popstate', () => {
    //   this.showProgressBar = false;
    //   // Close loading bar when the back button is clicked
    //   this.closeLoadingbar();
    //   this.reloadWithoutWhiteScreen();
    // });
    const translateService = this.translateService.onLangChange.subscribe(()=>{
      this.getActivity();
    });

    this.eventSubscription.push(translateService);
    setTimeout(() => {
      this.broadCastService.broadcast(MsgConstantConfig.REFRESH_VIEW_LANGUAGE_ID)
    }, 1000);
    const translateService2 = this.multilingualService.currentLanguageResponse.subscribe((data : any) => {
      if(data){
        this.selectedLanguage=data??'';
        this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
      }
     });
     this.eventSubscription.push(translateService2);
  }

  compareWithPstTime(){

    const date = new Date();
    const pst = date?.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    this.maintenanceStartWarning = ( pst>"19:29" && pst<"20:00")? true:false;
    this.isMaintenancModeOn = ( pst>="20:00" || pst<"01:00")? true:false;
    if(this.isMaintenancModeOn){
      this.maintenanceStartWarning=false;
    }
  }

  ngAfterViewInit(): void {
    this.loanID = Number(this.route.url.split('/')[5]);

    const routeEvents8 = this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.loanID) {
          if (this.selectedChart === 'act')
            this.getActivity();
        }
      }
    });
    this.eventSubscription.push(routeEvents8);
    if(this.loanID){
      // this.getLoanDetailByID(this.loanID);
      // this.getLTVChartData(this.loanID);
      // //get Activities
      //if(this.selectedChart==='act')
      // this.getActivity();
      // // this.getLoanAssignmentHistory(this.loanID);


      const url = `${PathConfig.STANDARD_CONFIGURATIONS}`;

      this.httpService.get(url).subscribe(
        (res) => {
          if (!res) {

          } else {
            this.take_payment_url = res?.payment_portal_url != null ? res?.payment_portal_url : '';
            this.queueDetailServiceService.updateStandardSettingResponse(res);

          }
        });

    }

  }


  TakePaymentPermissionType(): string {

    let codeType = '';
    const permisions: Array<any> = this.userService.PERMISSIONS;
    //  console.warn("Permitions:",permisions);
    permisions.forEach((item: string) => {
      const modulePermission = `specialpermission:post_payment`
      if (item.indexOf(modulePermission) > - 1) {
        if (item.indexOf('NON_CASH_PAYMENTS_ONLY') > -1)
          codeType = "NON-CASH-SYSTEM";
        else if (item.indexOf('CASH_PAYMENTS_ONLY') > -1)
          codeType = "CASH";
        else if (item.indexOf('YES') > -1)
          codeType = "YES";
        else
          codeType = "NO";

      }

    });
    return codeType;
  }



  verifyPin() {
    if (this.appform.valid) {
      const params = this.appform.getRawValue();
      const url = `${PathConfig.VERIFY_PIN}`;
      this.httpService.post(url, params).subscribe({
        next: (res) => {


          if (res?.message != 'User not found' && res?.message != 'User validation failed') {
            this.msgService.showSuccess(res?.message);
            this.TakePaymentPermission = true;
          }
          else {
            this.msgService.showError(res?.message);
          }

          this.appform.reset();
        },
        error: (err) => {
          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg) => this.msgService.showError(msg));
        }
      });

    }


  }





  loadLoansIDs() {
    const url = `${PathConfig.LOANS}?sort_by=${this.sortBy}&sort_order=${this.orderBy}&limit=${this.pageLimit}&${this.queueType}=1&page=${this.currentPage}`;
    this.httpService.get(url).subscribe(
      (response) => {
        this.queueList = response.data;


      },
      (err) => {
        this.msgService.showError(err.error.message);
      }
    );

  }

  /**
   * Get Activity Notes / Types
   */
  getActivity() {
    this.activityList = [];
    this.activityTypes = [];
    this.isActivityChartRender = false;
    const start_date = moment().subtract(29, 'days').format('YYYY-MM-DD');
    const end_date = moment().format('YYYY-MM-DD');
    forkJoin([
      this.httpService.get(
        `${PathConfig.LOAN_ACTIVITY_NOTES}?loan_id=${this.loanID}&sort_by=activity_at&sort_order=desc&start_date=${start_date}&end_date=${end_date}`
      ),
      this.httpService.get(`${PathConfig.ACTIVITY_TYPES}`),
    ]).subscribe(
      (res) => {
        this.activityList = res[0];

        this.activityTypes = res[1]?.length? res[1] :this.activityTypes ;


        this.activityList?.forEach((activity: any) => {
          const item = res[1]?.find(
            (item: any) => item?.id == activity?.activity_type_id
          );
          activity.activity_type = item?.title;
        });
        //create Activity Chart
        if (this.selectedChart === 'act')
          this.createActivityChart(this.activityList, this.activityChartDurInDays);

        //created slider Activity
        //this.getActivityByPagination(this.activityPageCnter);
      },
      (err) => {
        this.msgService.showError(err.error.message);
      }
    );
  }

  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  // convertLocalTimeZone(d:any)
  // {
  //  var testDateUtc = var testDateUtc = moment.tz(d,ConstantConfig.SERVER_TIMEZONE);
  //  return moment(testDateUtc).local().format("MM/DD/YY hh:mm:ss A");

  // }


  // get loan detail
  getLoanDetailByID(id: Number) {
    this.isLoanApiPatching = true;
    this.selecteduser= this.userService.getUserInfo("id");


    this.activatedRoute.queryParams
      .subscribe(params => {
        let priority_status = params['account_work_activity_priority_status'];
        this.selectedMainStatus = [null, '', undefined].includes(params['loan_status']) ? null : params['loan_status'];
        this.selected_sub_status = [null, '', undefined].includes(params['loan_sub_status_label']) ? null : params['loan_sub_status_label'];
        this.selecteduser = [null, undefined].includes(params['user_id']) ? this.selecteduser?.toString() : params['user_id']?.toString();
        this.selectedpriority = [null, '', undefined].includes(params['account_work_activity_priority_status']) ? null : priority_status?.split(',');
        this.pri = this.selectedpriority?.length > 0 ? this.selectedpriority?.toString() : '';
        this.selectedPortfolio = [null, '', undefined].includes(params['portfolio_id']) ? null : params['portfolio_id'];

      })

    // this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
    this.isAvgChartRenderd = false;
    const url = `${PathConfig.LOANS}/${id}?sort_by=${this.sortBy}&sort_order=${this.orderBy}&limit=${this.pageLimit}&${this.queueType}=1&page=${this.currentPage}&account_work_activity_priority_status=${this.pri ?? ''}&user_id=${this.selecteduser}&loan_status=${this.selectedMainStatus ?? ''}&loan_sub_status_label=${this.selected_sub_status ?? ''}&portfolio=${this.selectedPortfolio ?? ''}`;
    this.httpService.get(url).subscribe((res) => {

      this.queueDetailServiceService.updateQueueDetailResponse(res);
      this.loanInProgressStatus = res?.processing_status === 'IN_PROGRESS' ? true : false;
      this.loanDetail = res;
      this.loanDetailResponse=res;
      this.is_next_Disable=this.loanDetail?.next_loan_key==null?true:false;
      if(this.loanDetail?.latest_repo_review==null || this.loanDetail?.latest_repo_review?.after?.last_repo_review_date==undefined || this.loanDetail?.latest_repo_review?.after?.last_repo_review_date=='')
      this.lastRepoReviewDate="No";
      else
        this.lastRepoReviewDate = "Yes";

      // var d1 = moment(new Date(this.loanDetail?.latest_repo_review?.after.last_repo_review_date), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss");
      // var d1=moment(d1).format("X");
      // //Date.parse(this.loanDetail?.latest_repo_review?.after.last_repo_review_date);
      // var d2 = moment(new Date(this.loanDetail?.latest_kickback_repo_review?.after.last_repo_review_date), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss") //Date.parse(this.loanDetail?.latest_kickback_repo_review?.after.last_repo_review_date);
      // var d2=moment(d2).format("X");
      let d1=this.loanDetail?.latest_repo_review?.after.last_repo_review_date;
      let d2=this.loanDetail?.latest_kickback_repo_review?.after.last_repo_review_date;

      if (d1 < d2) {
        this.submitRepoReviewStatus = 1;
      }

      else if (d1 > d2) {
        this.submitRepoReviewStatus = 2;
      }

      else if (d1 == d2) {
        this.submitRepoReviewStatus = 0;
      }
      else {
        this.submitRepoReviewStatus = -1;
      }

      this.repo_sub_status = this.loanDetail?.sub_status;
      let amt_due_plus_sus = this.loanDetail?.amount_due_plus_sus;
      let cur_paymeny = this.loanDetail?.current_payment;
      let pay_per_to_roll = this.loanDetail?.payment_percentage_to_roll;
      let late_fee = this.loanDetail?.account_late_fees_balance;
      let nsf_fee = this.loanDetail?.account_nsf_fees_balance;
      let other_fee = this.loanDetail?.account_other_fees_balance;
      let round: number = (amt_due_plus_sus || 0) / (cur_paymeny || 0);
      let round_value;
      round_value = parseInt(round.toString())
      this.t1_sus = (amt_due_plus_sus || 0) - ((cur_paymeny || 0) * round_value);
      this.t2_sus = (cur_paymeny || 0) * (1 - (pay_per_to_roll || 0));
      this.sus_value = this.t1_sus > this.t2_sus ? 0 : this.t1_sus;
      this.amt_due_value = (amt_due_plus_sus || 0) - this.sus_value;
      this.min_value = (cur_paymeny || 0) - ((cur_paymeny || 0) * (1 - (pay_per_to_roll || 0)) - this.sus_value);
      this.totalamountdue = this.amt_due_value + (late_fee || 0) + (nsf_fee || 0) + (other_fee || 0);
      this.plus_sus = this.totalamountdue + this.sus_value;
      this.amount_to_roll = (this.amt_due_value + this.sus_value) - ((cur_paymeny || 0) + ((cur_paymeny || 0) * (1 - (pay_per_to_roll || 0))));
      /*************************** */
      // create off of rolls chart
      // this.isAssestRecoverySection
      // ? this.createAvgRatingChart(res?.odds_of_default_pbar || 0)
      // : this.createAvgRatingChart(res?.odds_of_default || 0);
      // this.createAvgRatingChart(res?.odds_of_roll?.avg_odds_of_roll || 0);
      this.createNBARChart(res);
      this.getActivityByPagination(this.activityPageCnter);
      this.loadingBarStarter();
      setTimeout(() => this.createOddsOfRollChart(), 2000);
      this.current_fee_balance_paid = 0;
      this.current_fee_balance_paid = this.loanDetail?.late_fees_paid + this.loanDetail?.nsf_fees_paid + this.loanDetail?.other_fees_paid;
      if (this.selectedChart === 'pmts') {
        this.getPmtChartData(this.loanID);
      }
      if (this.selectedChart === 'buckets') {
        this.getBucketChartData(this.loanID);
      }

      if(this.loanID){
         this.getLTVChartData(this.loanID);
      }


      this.transactionLoadingBarStarter();
    this.isLoanApiPatching = false;
    });

  }

  createOddsOfRollChart() {
    const dial = document.getElementById('dial');

    if (dial != null) {
      let odds_of_roll_precentage = this.loanDetail?.odds_of_roll?.avg_odds_of_roll ?? 0;

      if (odds_of_roll_precentage < 50 && odds_of_roll_precentage > 0) {
        this.show_avg_odds_of_roll = (100 - odds_of_roll_precentage).toFixed(1);
        odds_of_roll_precentage = 50 - odds_of_roll_precentage;
      } else if (odds_of_roll_precentage === 0) {
        this.show_avg_odds_of_roll = '99.0';
        odds_of_roll_precentage = 0;
      } else if (odds_of_roll_precentage === 100) {
        this.show_avg_odds_of_roll = '99.0';
      } else {
        this.show_avg_odds_of_roll = (odds_of_roll_precentage).toFixed(1);
      }

      switch (odds_of_roll_precentage) {
        case 0:
          odds_of_roll_precentage = -93;
          break;
        case 25:
          odds_of_roll_precentage = -47;
          break;
        case 50:
          odds_of_roll_precentage = 0;
          break;
        case 75:
          odds_of_roll_precentage = 48;
          break;
        case 100:
          odds_of_roll_precentage = 93;
          break;
        default:
          odds_of_roll_precentage = odds_of_roll_precentage * 1.8;
          odds_of_roll_precentage = odds_of_roll_precentage >= 90 ? odds_of_roll_precentage - 90 : odds_of_roll_precentage * -1;
          break;
      }
      dial.style.transform = 'scale(1.1) rotate(' + odds_of_roll_precentage + 'deg)';
    }

  }

  // // get loan assignment logs
  // getLoanAssignmentHistory(id: Number) {
  //   this.httpService
  //     .post(PathConfig.LOAN_ASSIGNMENT_LOGS, { loan_id: id })
  //     .subscribe((res) => {
  //       this.assignmentHistory = res || [];
  //     });
  // }

  // get loan list by nbar/porfolio/dqbucket for generating NBAR chart on left side
  // this function Initialises the NBAR chart
  createNBARChart(detail: LoanDetail) {

    this.chartNetBalAtRiskRender = false;

    const nabrORpbarFilter = this.isAssestRecoverySection ? 'pbar=1' : 'nbar=1';
    // const url = `${PathConfig.LOANS}?loan_id=${
    //   this.loanID
    // }&${nabrORpbarFilter}&portfolio_name=${
    //   detail.portfolio_name || ''
    // }&dq_bucket=${detail.dq_bucket || ''}`;
    const url = `${PathConfig.LOANS}?${nabrORpbarFilter}&portfolio_name=${
      detail.portfolio_name || ''
    }`;
    this.httpService.get(url).subscribe((res) => {
      //remove current selected loan from list
      let flag = 0;
      let searchedObj = res?.find((obj: any) => obj.id === detail?.id);
      if (searchedObj) {
        flag = 1;
      } else {
        flag = 0;
      }


      const response = res.filter((loan: any) => loan.id != detail?.id);

      let othersArr: Array<any> = [];
      let currentArr: Array<any> = [];
      // PBAR SECTION
      if (this.isAssestRecoverySection) {



        currentArr = (flag == 1 || this.loanDetail?.status == 'CHARGED-OFF') ? [
          [
            Math.round(detail?.current_principal) || 0,
            detail?.odds_of_default_pbar || 0,
          ],
        ] : [];

        response.forEach((item: any) => {
          if (item?.current_principal && item?.odds_of_default_pbar) {
            othersArr.push([
              Math.round(item?.current_principal || 0),
              item?.odds_of_default_pbar || 0,
            ]);
          }
        });

      } else {
        // NBAR SECTION
        currentArr = (flag == 1 || this.loanDetail?.status == 'ACTIVE') ? [
          [Math.round(detail?.current_net_balance) || 0, detail?.odds_of_default || 0],
        ] : [];
        response.forEach((item: any) => {
          if (item?.current_net_balance && item?.odds_of_default) {
            othersArr.push([
              Math.round(item?.current_net_balance) || 0,
              item?.odds_of_default || 0,
            ]);
          }
        });
      }


      let final_series: any = [];
      let final_color: any = [];
      if (othersArr.length > 0 && currentArr.length > 0) {
        final_series = [
          {
            name: 'Others',
            data: othersArr,
          },
          {
            name: 'Current',
            data: currentArr,
          },
        ];
        final_color = [this.isAssestRecoverySection ? '#FF7F50' : '#008FFB', '#FFF06A'];
      }
      else if (othersArr.length > 0 && currentArr.length == 0) {
        final_series = [
          {
            name: 'Others',
            data: othersArr,
          }
        ];
        final_color = [this.isAssestRecoverySection ? '#FF7F50' : '#008FFB'];
      }
      else if (othersArr.length == 0 && currentArr.length > 0) {
        final_series = [
          {
            name: 'Current',
            data: currentArr,
          }
        ];
        final_color = ['#FFF06A'];
      }

      this.chartNetBalAtRisk = {
        series: final_series,
        chart: {
          id: 'net_ba_at_risk',
          type: 'scatter',
          height: 250,
          width: '100%',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          background: 'transparent',
          animations: {
            enabled: false,
          },
        },
        colors: final_color,
        yaxis: {
          tickAmount: 3,
          labels: {
            formatter: function (value: number) {
              return value + '%';
            },
          },
          title: {
            text: this.isAssestRecoverySection ? 'Odds of Collectability' : 'Odds of Default',
          },
        },
        xaxis: {
          tickAmount: 2,
          labels: {
            formatter: function (value: number) {
              var newnum = Math.round(value);
              return '$' + newnum.toLocaleString();
            },
          },
        },
        markers: {
          size: 6,
          hover: {
            size: 10,
          },
        },
        tooltip: {
          followCursor: false,
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return '';
              },
            },
          },
        },
        grid: {
          borderColor: this.theme == 'dark' ? ConstantConfig.chartBgColor.dark: ConstantConfig.chartBgColor.light,
          clipMarkers: false,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          labels: {
            names: final_series.map((s: any) => s.name),
          },
          onItemClick: {
            toggleDataSeries: false
          }
        },
        theme: {
          mode: this.theme == 'dark' ? 'dark' : 'light',
        },
      };

    });
    this.chartNetBalAtRiskRender = true;
  }

  // Open Add Note Modal
  openAddNoteModal() {
    const modalRef = this.modalService.open(NewNoteComponent, {
      centered: true,
      size: 'md',
      windowClass: 'draggable-container',
      backdrop: false,
    });
    modalRef.componentInstance.loanDetail = this.loanDetail;
    modalRef.componentInstance.loanID = this.loanID;
    modalRef.componentInstance.activityTypes = this.activityTypes;
    modalRef.componentInstance.loanID = this.loanID;
    //  modalRef.componentInstance.permission = this.loanID;
  }

  openProcessExtensionModal() {

    this.getLoanInfo(this.loanID).then(() => {

      setTimeout(() => {
        const url =`${PathConfig.LOAN_PREVIOUS_TRANSACTION}?loan_id=${this.loanID}`;

        this.httpService.get(url).subscribe((res)=>{
         this.previousTransaction = res;

         const modalRef = this.modalService.open(ProcessExtensionComponent, {
           centered: true,
           size: 'lg',
           windowClass:'draggable-container',
           backdrop:false,
         });
         modalRef.componentInstance.previousTransaction = this.previousTransaction;
         modalRef.componentInstance.loanDetail = this.loanDetail;
         modalRef.componentInstance.loanID = this.loanID;
        });
      }, 1000);

   });
  }

  openWaiveFeesModal(content: any) {
    const modalRef = this.modalService.open(WaiveFeeComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'draggable-container',
      backdrop: false,
    });
    modalRef.componentInstance.loanDetail = this.loanDetail;
    modalRef.componentInstance.loanID = this.loanID;
    //this.modalService.open(content, { centered: true, size: 'lg' });
  }
  openReamortizeLoanModal() {
    const modalRef = this.modalService.open(ReamortizeLoanComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'draggable-container',
      backdrop: false,
    });
    modalRef.componentInstance.loanID = this.loanID;
    modalRef.componentInstance.loanDetail = this.loanDetail;
  }

  openDueDateLoanModal() {
    const modalRef = this.modalService.open(DueDateComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'draggable-container',
      backdrop: false,
    });
    modalRef.componentInstance.loanID = this.loanID;
    modalRef.componentInstance.loanDetail = this.loanDetail;
  }


  openSubmitRepoReviewModal(content: any) {
    const modalRef = this.modalService.open(RepoReviewComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'draggable-container',
      backdrop: false,
    });
    modalRef.componentInstance.loanDetail = this.loanDetail;
    modalRef.componentInstance.loanID = this.loanID;
  }
  openRouteAccountModal(content: any, showHistory: boolean = false) {
    const modalRef = this.modalService.open(RouteAccountsComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'draggable-container',
      backdrop: false,
    });
    modalRef.componentInstance.isRouteShowHistory = showHistory;
    modalRef.componentInstance.loanDetail = this.loanDetail;
    modalRef.componentInstance.loanID = this.loanID;
  }

  openManualChargeOffModal(content: any, showHistory: boolean = false) {
    const modalRef = this.modalService.open(ManualChargeOffComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'draggable-container',
      backdrop: false
    });
    modalRef.componentInstance.isRouteShowHistory = showHistory;
    modalRef.componentInstance.loanDetail = this.loanDetail;
    modalRef.componentInstance.loanID = this.loanID;
  }
  openModalStartStopInterestAccrual(showHistory: boolean = false) {
    const modalRef = this.modalService.open(StartStopInterestAccrualComponent, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'lg',
      windowClass: 'draggable-container',
      backdrop: false
    });
    modalRef.componentInstance.isRouteShowHistory = showHistory;
    modalRef.componentInstance.loanDetail = this.loanDetail;
    modalRef.componentInstance.loanID = this.loanID;
  }

  //Modal for Late Fee Accural
  openModalStartStopLateFeeAccrual(showHistory: boolean = false) {
    const modalRef = this.modalService.open(StartStopFeeAccrualsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'lg',
      windowClass: 'draggable-container',
      backdrop: false
    });
    modalRef.componentInstance.isRouteShowHistory = showHistory;
    modalRef.componentInstance.loanDetail = this.loanDetail;
    modalRef.componentInstance.loanID = this.loanID;
  }


  // openGetApprovalModal(content: any) {
  //   this.modalService.open(content, { centered: true, size: 'sm', windowClass:'draggable-container',
  //   backdrop:false });
  // }
  // openNewNoteModal(content: any) {
  //   this.modalService.open(content, { centered: true, size: 'lg', windowClass:'draggable-container',
  //   backdrop:false });
  // }

  //changed the inHTML popup to saperate component take pmt
  openTakePaymentTypeModal(content: any) {
    const modalRef = this.modalService.open(TakePmtComponent, {
      centered: true, size: 'md', windowClass: 'draggable-container',
      backdrop: false
    });
    modalRef.componentInstance.take_payment_url = this.take_payment_url;
    modalRef.componentInstance.previousTransaction = this.previousTransaction;
    modalRef.componentInstance.loanDetail = this.loanDetail;
    modalRef.componentInstance.loanID = this.loanID
  }

  // openTakePaymentModal(content: any) {
  //   const url =`${PathConfig.LOAN_PREVIOUS_TRANSACTION}?loan_id=${this.loanID}`;
  //   this.httpService.get(url).subscribe((res)=>{
  //     this.modalService.dismissAll();
  //     this.previousTransaction = res;
  //   const modalRef = this.modalService.open(TakePaymentComponent, {
  //     centered: true,
  //     size: 'xl',
  //     windowClass:'draggable-container',
  //     backdrop:false,
  //   });
  //   modalRef.componentInstance.previousTransaction = this.previousTransaction;
  //   modalRef.componentInstance.loanDetail = this.loanDetail;
  //   modalRef.componentInstance.loanID = this.loanID;
  //   })
  //   //this.modalService.open(content, { centered: true, size: 'xl' });
  // }

  // openReverseTransactionModal(content: any) {
  //   this.modalService.open(content, { centered: true, size: 'md',      windowClass:'draggable-container',
  //   backdrop:false, });
  // }

  openKickbackRepoReviewModal(content: any) {
    const modalRef = this.modalService.open(KickabckReviewComponent, {
      centered: true,
      size: 'xl',
      windowClass: 'draggable-container',
      backdrop: false,
    });
    modalRef.componentInstance.loanDetail = this.loanDetail;
    modalRef.componentInstance.loanID = this.loanID;
  }

  /**
   * goToRoute
   */
  public goToRoute(moduleName: any) {
    let clientId = this.userService.getClientId();
    const routeURL = `${clientId}/home/servicing/queues-detail/${this.loanID}/${moduleName}`;
    this.route.navigate([routeURL], { queryParamsHandling: "merge" });
  }

  formatLtvChartData(data: any, sumby: string) {
    let val = _.groupBy(data, 'year');
    const arr = [];
    for (let item in val) {
      const group = _.sumBy(val[item], sumby);
      arr.push({
        year: item,
        total: group?.toFixed(2),
      });
    }
    const resultArr = arr.map((item) => {
      return item.total;
    });

    return resultArr;
  }

  formatLtvChartDataMonthly(data: Array<any>, sumby: string) {
    const resultArr = _.values(data).map((item) => {
      return { x: item['year'] + "-" + item['month'], y: item[sumby] };
    });
    return resultArr;
  }

  fomateLtvChartCurrentDate(value: any, data: any) {
    const resultArr = _.keys(data).map((item, index) => {
      return item == moment().year().toString() ? value : 0;
    });
    return resultArr;
  }

  public initCharts(dt: any): void {
    const loanPrincArr         = this.formatLtvChartDataMonthly(dt.loan_principal,'principal');
    const collatralArr         = this.formatLtvChartDataMonthly(dt.collateral_value, 'value');
    const xaxisLables          = _.keys(_.groupBy(dt.collateral_value, 'year'));
    const currentYearIndex     = _.indexOf(_.keys(dt.collateral_value),moment().format('YYYY-MM').toString());
    const yearMonths           = loanPrincArr.map((x)=>x.x);
    this.chart2options = {
      series: [
        {
          name: 'Principal Balance',
          data: loanPrincArr,
        },
        {
          name: 'Collateral Value',
          data: collatralArr,
        },
      ],
      chart: {
        id: 'tw',
        type: 'line',
        height: 250,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        background: 'transparent'
      },
      colors: ['#00b0f0', '#dc3545'],
      yaxis: {
        tickAmount: 5,
        labels: {
          minWidth: 40,
          formatter: function (value: number) {
            return '$' + value?.toLocaleString();
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      markers: {
        size: 0.1,
        strokeColors: ['#00b0f0', '#dc3545'],
        strokeOpacity: 0,
        discrete: [{
          seriesIndex: 0,
          dataPointIndex: currentYearIndex,
          fillColor: '#ffff00',
          strokeColor: '#fff',
          size: 5,
          shape: "circle" // "circle" | "square" | "rect"
        }, {
          seriesIndex: 1,
          dataPointIndex: currentYearIndex,
          fillColor: '#ffff00',
          strokeColor: '#eee',
          size: 5,
          shape: "circle" // "circle" | "square" | "rect"
        }]
      },
      tooltip: {
        followCursor: false,
        x: {
          show: false,
        },
        marker: {
          show: false,
        },
        custom: function (value: any, timestamp: any, dataPointIndex: any) {
          if (value?.series[0][value?.dataPointIndex] != null) {
            return '<div style="padding:20px" align="left">' +
              '<b>Principal Balance </b>: $' + value?.series[0][value?.dataPointIndex] + '<br/>' +
              '<b>Collateral Value  </b>: $' + value?.series[1][value?.dataPointIndex] + '<br/>' +
              '<b>Date</b>              : ' + moment(yearMonths[value?.dataPointIndex]).format('MMM YYYY') + '<br/>' +
              '</div>';
          }
          else {
            return '';
          }
        }
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        tickAmount: Math.round(loanPrincArr.length / 12) - 1,
        type: 'numeric',
        title: {
          text: 'Years',
        },
        labels: {
          rotate: 0,
          formatter: function (value: string) {
            return _.indexOf(xaxisLables, moment(value).format('YYYY'));
            // return value;
          }
        }, tooltip: {
          enabled: false,
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        floating: false,
        offsetY: 15,
        offsetX: -5,
        labels: {
          colors: this.theme == 'dark' ? ConstantConfig.chartBgColor.dark: ConstantConfig.chartBgColor.light,
          useSeriesColors: false,
        },
        markers: {
          radius: 2,
          height: 2,
          width: 15,
        }
      },
      theme: {
        mode: this.theme == 'dark' ? 'dark' : 'light',
      },
    };
  }

  goBack(): void {
    const clientId = this.userService.getClientId();
    const queryParams = {
      qtype: this.queueType,
      sortby: this.sortBy,
      orderby: this.orderBy,
      limit: this.pageLimit,
      page: this.currentPage,
      account_work_activity_priority_status: this.pri ?? '',
      user_id: this.selecteduser,
      loan_status: this.selectedMainStatus ?? '',
      loan_sub_status_label: this.selected_sub_status ?? ''
    };

    this.route.navigate([`${clientId}/home/servicing/queues/assigned`], { queryParams });
  }
  validateRoute(route: string) {
    let currentRoutes = window.location.pathname.split('/');
    return currentRoutes[currentRoutes.length - 1] === route;
  }

  /**
   * On New Activity Submit
   */
  onActivitySubmit() {
    if (this.addActivityForm.valid && !this.addActivityFormSubmit) {
      this.addActivityFormSubmit = true;
      const params = this.addActivityForm.value;
      params.loan_id = this.loanID;

      this.httpService.post(PathConfig.LOAN_ACTIVITY_NOTES, params).subscribe(
        (response: any) => {
          this.msgService.showSuccess(response?.message);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACTIVITY);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
          this.modalService.dismissAll();
          this.addActivityFormSubmit = false;

          //reload Activity Notes
          if (this.selectedChart === 'act')
            this.getActivity();
        },
        (err) => {
          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg) => this.msgService.showError(msg));
          this.modalService.dismissAll();
          this.addActivityFormSubmit = false;
        }
      );
    }
  }

  /**
   * Get Preferedd Number Info
   * @param fieldName
   * @returns
   */
  getPreferredNo(fieldName: string) {
    const field = this.loanDetail ? this.loanDetail[fieldName] : '';
    let prefferdNum = '';
    if (field) {
      if (field.preferred_phone == 'MOBILE') {
        prefferdNum = field.mobile_phone;
      } else if (field.preferred_phone == 'HOME') {
        prefferdNum = field.home_phone;
      } else if (field.preferred_phone == 'WORK') {
        prefferdNum = field.work_phone;
      }
    }
    return prefferdNum;
  }

  /**
   * Next / prev button functionality for Activity Notes
   * @param type
   */

  goToActivity(type: string) {

    switch (type) {
      case 'NEXT':
        this.activityPageCnter++;
        break;
      case 'PREV':
        this.activityPageCnter--;
        break;
    }
    this.getActivityByPagination(this.activityPageCnter);
  }

  /**
   * Get Activity By Limit And Pagination
   * @param activityPageCnter
   */
  getActivityByPagination(page: number) {
    this.httpService
      .get(
        `${PathConfig.LOAN_ACTIVITY_NOTES}?loan_id=${this.loanID}&limit=1&sort_by=activity_at&sort_order=desc&page=${page}`
      )
      .subscribe((item) => {
        this.currentActivity = item?.data?.length > 0 ? item?.data[0] : null;

        this.activityTotalSize = item?.pagination ? item?.pagination?.total : 0;
      });
  }
  getAgentName(): string {
    if (this.currentActivity && this.currentActivity?.activity_note_agent) {
      return this.toTitleCase(`${this.currentActivity.activity_note_agent?.firstname} ${this.currentActivity?.activity_note_agent?.lastname}`);
    } else if (this.currentActivity && this.currentActivity?.activity_note_agent_id) {
      return this.currentActivity.activity_note_agent_id;
    } else {
      return 'System';
    }
  }
  // Truncated note display logic
  get truncatedNote(): string {
    const note = this.currentActivity?.note || '';
    if (this.showFullNote) {
      return note; // Show the full note if toggled
    } else {
      return note.length > 200 ? note.substring(0, 200) + '...' : note; // Truncate if more than 200 chars
    }
  }

  // On clicking "see more", toggle the full note display
  toggleNoteDisplay(): void {
    this.showFullNote = !this.showFullNote; // Toggle between full and truncated
  }
  toTitleCase(str: string): string {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }


  /**
   * Create Activity Chart
   * @param data
   * @param duration
   */
  createActivityChart(data: Array<any>, duration: number = 30) {
    var offset = new Date().getTimezoneOffset() * 60;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let utcOffset = moment.tz(Date.now(), timezone).utcOffset() * 60; // offset in seconds
    let localTimstamp = Date.now() + utcOffset;  // use this as the timestamp in apexcharts
    //prepare chart data
    let act_data = data;
    let ptp_date = _.cloneDeep(data).filter((item) => (item.ptp_date !== null)).map((item) => { item.ptp_status = 'PENDING'; return item });
    let sbptp_date = _.cloneDeep(data).filter((item) => (item.ptp_date !== null && item.ptp_status !== 'PENDING' && item.ptp_status !== 'IMPORTED'));
    let dateArr: Array<any> = [];
    const chartdata: Array<any> = [];

    let show_legends: boolean = true;

    for (let i = 0; i < duration; i++) {

      const date = moment()
        .subtract(i, 'days')
        .startOf('date')
        .toDate()
        .getTime();
      dateArr?.push(date);
    }
    dateArr = dateArr?.reverse();

    const chartImg: Array<any> = [];
    const uniqActivities = _.uniqBy(data, 'activity_type');
    const uniqPTPActivies = _.uniqBy(ptp_date, 'ptp_status');
    const uniqSBPTPActivies = _.uniqBy(sbptp_date, 'ptp_status');
    uniqActivities?.forEach((activity: any) => {
      if (activity.activity_type) {

        let cData: Array<any> = [];
        let d: any;
        // let isWorkTime : boolean = false;

        dateArr.forEach((date) => {

          let item = _.chain(data)
            .filter((item) => item.activity_type == activity.activity_type)
            .find((item) => {

              d = moment(moment.tz(item.activity_at, ConstantConfig.SERVER_TIMEZONE)).local().format("YYYY-MM-DD hh:mm:ss A");

              const activity_at = moment(d)
                .startOf('date')
                .toDate()
                .getTime();
              return activity_at == date;
            })
            .value();
          date = date + offset;

          let activity_time = item
            ? Number(moment(d).format('HH.mm'))
            : null;
          // activity_time = activity_time &&( ( activity_time >=6 && activity_time <=21) || item?.activity_type==='Payment Received' || item?.activity_type=== 'BPTP')?
          // activity_time:null;
          if (activity_time || activity_time === 0) {
            activity_time = (activity_time < 6) ? 6 : activity_time;
            activity_time = (activity_time > 21) ? 6 : activity_time;
          }

          // if(activity_time && !isWorkTime){
          //   isWorkTime = true;
          // }
          cData.push([moment(moment.utc(date)).add(1, 'days').local().format("YYYY-MM-DD"), activity_time, item?.id]);
        });
        // data?.forEach((datum) => {
        //   // let item = datum.activity_at;
        //   let item =  moment(moment?.utc(datum?.activity_at))?.local()?.format("MM/DD/YYYY hh:mm:ss A");
        //   let id = datum?.id;

        //   const activity_time = item
        //     ? Number(moment(item)?.format('HH.mm'))
        //     : null;
        //     let activity_date = item
        //     ? Number(new Date(moment(item)?.format('YYYY-MM-DD'))?.getTime())
        //     : null;

        //   if(datum?.activity_type==activity?.activity_type)
        //   cData?.push([activity_date, activity_time,id]);
        // });
        //  cData?.unshift([dateArr[0],null,null]);
        // cData?.push([dateArr[dateArr?.length-1],null,null]);
        const activity_type = this.activityTypes.find((item) =>
          item?.title == activity?.activity_type
        )?.id;
        const obj = {
          name: activity?.activity_type,
          type_id: activity_type,
          is_ptp: 0,
          data: cData,
        };
        // // get icon source
        // const iconSource = ConstantConfig.ACTIVITY_TYPES_IMG?.find(
        //   (item) => item?.activity_type == activity?.activity_type_id
        // );
        // iconSource ? chartImg?.push(iconSource?.img) : chartImg?.push('');
        // if(isWorkTime)
        chartdata?.push(obj);
      }
    });

    uniqPTPActivies?.forEach((activity: any) => {
      if (activity.activity_type) {

        let cData: Array<any> = [];
        let d: any;
        // let isWorkTime : boolean = false;
        dateArr.forEach((date) => {
          let item = _.chain(ptp_date)
            .find((item) => {
              d = moment(moment.utc(item.activity_at)).local().format("YYYY-MM-DD hh:mm:ss A");
              const activity_at = moment(d)
                .startOf('date')
                .toDate()
                .getTime();
              return activity_at == date;
            })
            .value();
          date = date + offset;

          let activity_time = item
            ? Number(moment(d).format('HH.mm'))
            : null;
            if(activity_time || activity_time===0 ){
              activity_time = (activity_time < 6 ) ? 6 : activity_time;
              activity_time = (activity_time > 21 )  ? 6 : activity_time;
            }
            // activity_time = activity_time && activity_time >=6 && activity_time <=21?activity_time:null;
            // if(activity_time && !isWorkTime){
            //   isWorkTime = true;
            // }
          cData.push([moment(moment.utc(date)).add(1, 'days').local().format("YYYY-MM-DD"), activity_time,item?.id]);
        });

        const obj = {
          name: this.ptpMarkers.find((item) => item.id == 0).name,
          type_id: this.ptpMarkers.find((item) => item.id == 0).id,
          is_ptp: 1,
          data: cData,
        };
        // get icon source
        // const iconSource = ConstantConfig.ACTIVITY_TYPES_IMG?.find(
        //   (item) => item?.activity_type == activity?.activity_type_id
        // );
        // iconSource ? chartImg?.push(iconSource?.img) : chartImg?.push('');
        // if(isWorkTime)
        chartdata?.push(obj);
      }
    });

    uniqSBPTPActivies?.forEach((activity: any) => {
      if (activity.activity_type) {

        let cData: Array<any> = [];
        let d: any;
        // let isWorkTime : boolean = false;
        dateArr.forEach((date) => {
          let item = _.chain(sbptp_date)
            .filter((item) => item.ptp_status == activity.ptp_status)
            .find((item) => {
              d = moment(moment.utc(item.ptp_date + " 11:00:00")).local().format("YYYY-MM-DD 00:00:00 A");
              const ptp_date = moment(d)
                .startOf('date')
                .toDate()
                .getTime();
              return ptp_date == date;
            })
            .value();
          date = date + offset;

          let activity_time = item
            ? Number(moment(d).format('HH.mm'))
            : null;
          // activity_time = activity_time && activity_time >=6 && activity_time <=21?activity_time:(item?.id)?11:null;
          // if(activity_time && !isWorkTime){
          //   isWorkTime = true;
          // }
          if (activity_time || activity_time === 0) {
            activity_time = (activity_time < 6) ? 6 : activity_time;
            activity_time = (activity_time > 21) ? 6 : activity_time;
          }
          cData.push([moment(moment.utc(date)).add(1, 'days').local().format("YYYY-MM-DD"), activity_time, item?.id]);
        });

        const obj = {
          name: this.ptpMarkers.find((item) => item.lable == activity?.ptp_status).name,
          type_id: this.ptpMarkers.find((item) => item.lable == activity?.ptp_status).id,
          is_ptp: 2,
          data: cData,
        };
        // // get icon source
        // const iconSource = ConstantConfig.ACTIVITY_TYPES_IMG?.find(
        //   (item) => item?.activity_type == activity?.activity_type_id
        // );
        // iconSource ? chartImg?.push(iconSource?.img) : chartImg?.push('');

        // if(isWorkTime)
        chartdata?.push(obj);
      }
    });

    if (!chartdata?.length) {

      show_legends = false;
      let dateArray: Array<any> = [];
      let cData: Array<any> = [];
      dateArr.forEach((date) => {

        date = moment(date)
          .add(1, 'days')
          .startOf('date')
          .toDate()
          .getTime() + offset;
        date = moment(moment.utc(date)).local().format("YYYY-MM-DD");

        dateArray.push(date);
      })
      //
      cData?.unshift([dateArray[0], null, null]);
      cData?.push([dateArray[dateArray?.length - 1], null, null]);
      dateArray = [];
      const obj = {
        name: '',
        data: cData,
      };
      chartdata?.push(obj);
    }

    // chartdata[0].data[29].push(11.14);
    // let chartdata2=[{name:'',data:[['2023-02-17',null],['2023-03-18',null]]}]

    let selected_colorArr: any = [];

    let legend_img = [];

    for (let i = 0; i < chartdata?.length; i++) {
      if (chartdata[i]?.is_ptp == 1) {

        selected_colorArr.push(this.ptpMarkers.find((item) => item.id == 0).color);
      } else if (chartdata[i]?.is_ptp == 2) {
        // chartImg?.push('')
        selected_colorArr.push(this.ptpMarkers.find((item) => item.id == chartdata[i]?.type_id).color);
      } else {
        // chartImg?.push('')
        selected_colorArr.push(this.colorArr[chartdata[i]?.type_id - 1]);
      }

      switch (chartdata[i]?.name) {

        case 'PTP':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[0]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/promise_to_pay.svg"></span>';
            });
          break;

        case 'Payment Received':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[1]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/payment_received.svg"></span>';
            });
          break;

        case 'BPTP':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[9]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/b_icon.svg"></span>';
            });
          break;

        case 'Phone Disconnected':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[33]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_wheat.svg"></span>';
            });
          break;

        case 'Inbound Call - NRPC':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[3]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_yellow.svg"></span>';
            });
          break;

        case 'Inbound Call - RPC':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[4]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker3" src="../assets/images/chart-icons/inbound_nrpc_green.svg"></span>';
            });
          break;

        case 'Outbound Call - NRPC':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[7]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker3" src="../assets/images/chart-icons/outbound_nrpc_yellow.svg"></span>';
            });
          break;

        case 'Outbound Call - RPC':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[6]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker3" src="../assets/images/chart-icons/outbound_nrpc_green.svg"></span>';
            });
          break;

        case 'Inbound Text':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[5]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_purple.svg"></span>';
            });
          break;

        case 'Outbound Text':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[2]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/outbound_nrpc_purple.svg"></span>';
            });
          break;

        case 'Voicemail':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[10]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker3" src="../assets/images/chart-icons/inbound_nrpc_beige.svg"></span>';
            });
          break;

        case 'Account Update':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[11]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_blue.svg"></span>';
            });
          break;

        case 'General Note':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[12]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_brown.svg"></span>';
            });
          break;

        case 'E-mail':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[13]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_burlywood.svg"></span>';
            });
          break;

        case 'Mail':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[14]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_dark-blue.svg"></span>';
            });
          break;

        case 'SMS':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[15]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_darkkhaki.svg"></span>';
            });
          break;

        case 'Chat':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[16]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_darkslategray.svg"></span>';
            });
          break;

        case 'Impound Note':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[17]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_gray.svg"></span>';
            });
          break;

        case 'Repo Note':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[18]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_light-green.svg"></span>';
            });
          break;

        case 'Skip Note':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[19]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_lightsalmon.svg"></span>';
            });
          break;

        case 'Liquidation Note':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[20]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_lightseagreen.svg"></span>';
            });
          break;

        case 'Insurance Note':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[21]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_lightsteelblue.svg"></span>';
            });
          break;

        case 'Bankruptcy Note':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[22]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_navy.svg"></span>';
            });
          break;

        case 'Title Note':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[23]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_orange.svg"></span>';
            });
          break;

        case 'Compliance Note':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[24]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_orangered.svg"></span>';
            });
          break;

        case 'Legal Note':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[25]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_palegreen.svg"></span>';
            });
          break;

        case 'Notification':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[26]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_palevioletred.svg"></span>';
            });
          break;

        case 'Submitted Repo Review':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[27]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_peru.svg"></span>';
            });
          break;

        case 'Kickback Repo Review':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[28]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_pink.svg"></span>';
            });
          break;

        case 'System Email':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[29]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_purpul.svg"></span>';
            });
          break;

        case 'System Text':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[30]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_red.svg"></span>';
            });
          break;

        case 'System Entry':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[31]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_salmon.svg"></span>';
            });
          break;

        case 'Repay':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[32]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_sky-blue.svg"></span>';
            });
          break;

        case 'SBT':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[34]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/inbound_nrpc_lightred.svg"></span>';
            });
          break;

        case 'SPTP':
          selected_colorArr[i] = this.theme == 'dark' ? '#1B1B1B' : '#ffffff';
          chartImg?.push(ConstantConfig.ACTIVITY_TYPES_IMG[35]?.img);
          legend_img.push(
            function () {
              return '<span><img class="apexcharts-legend-marker2" src="../assets/images/chart-icons/s_icon.svg"></span>';
            });
          break;

        default:
          chartImg?.push('');
          legend_img.push(
            function () {
              return '';
            });
          break;
      }

    }
    // create chart config
    this.activityChartOptions = {
      series: chartdata,
      chart: {
        height: 200,
        type: 'scatter',
        background: 'transparent',
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      xaxis: {

        // tickAmount: duration,
        tickAmount: 29,
        type: 'datetime',
        tickPlacement: 'on',
        // min: new Date('09 Jan 2023').getTime(),
        // hideOverlappingLabels: true,
        // yAxisIndex: 0,
        labels: {
          rotate: 270,
          rotateAlways: true,
          // showDuplicates: false,
          // datetimeUTC: false,
          formatter: function (value: any, timestamp: any) {
            return moment(timestamp).format('MM/DD');
          },
        },
      },
      yaxis: {
        tickAmount: 3,
        min: 6,
        max: 21,
        labels: {
          formatter: function (value: any, timestamp: any) {
            return moment(value, 'HH:mm').format('hh:mm A');
          },
        },
        title: {
          minWidth: 40,
          text: this.translateService.instant('ACTIVITY'),
        },
      },
      markers: {
        // customHTML: [
        //   function () {
        //     return '<span><img src="https://freesvg.org/img/Simple-Water-Icon.png" width="19" height="10"></span>';
        //   },
        //   function () {
        //     return '<span><i class="fab fa-instagram"></i></span>';
        //   }
        // ],
        size: 10,
        strokeWidth: 0,
        // fillOpacity: 1,
        // strokeColors: selected_colorArr,
        colors: selected_colorArr
        // ['#00B050','#3489D8','#FF0000','#FF5B5B','#FFFF00','#0899EC','#E4CAF7','#FFC000','#00B050','#A90D99','#F6D673','#01E768','#D8C2E8'],
      },
      theme: {
        mode: this.theme == 'dark' ? 'dark' : 'light',
      },
      tooltip: {
        custom: function(value: any, timestamp: any, dataPointIndex:any) {
          let id =chartdata[value?.seriesIndex].data[value?.dataPointIndex][2]??'';
          let data =  act_data?.find((item: any)=> item?.id == id);

          let agent_firstname=data?.activity_note_agent?.firstname??'System';
          let agent_lastname=data?.activity_note_agent?.lastname??'';
          let note=data?.note?.length < 100 ? data?.note : data?.note?.substring(0,100)+' ......';
          note = note? note:'';
          let note_type = data?.activity_type==='Payment Received' || null? 'Note' : 'Activity Note';
          let d = moment(moment.tz(data?.activity_at,ConstantConfig.SERVER_TIMEZONE))?.local()?.format("MM/DD/YYYY hh:mm:ss A") ??'';
          let ed = moment(data?.payment_effective_date)?.format("MM/DD/YYYY") ??'';

          if (ed && data?.activity_type === 'Payment Received') {

            return '<div style="padding:20px" align="left"><b>Type</b>: ' + data?.activity_type + '<br/>' +
              '<b>' + note_type + '</b>: ' + note + '<br/>' +
              '<b>Agent</b>: ' + agent_firstname + " " + agent_lastname
              + '<br/>' +
              '<b>Date</b>: ' + d + '<br/>' + '<b>Effective Date</b>: ' + ed + '<br/>' +
              '</div>';
          }
          else {

            return '<div style="padding:20px" align="left"><b>Type</b>: ' + data?.activity_type + '<br/>' +
              '<b>' + note_type + '</b>: ' + note + '<br/>' +
              '<b>Agent</b>: ' + agent_firstname + " " + agent_lastname
              + '<br/>' +
              '<b>Date</b>: ' + d + '<br/>' +
              '</div>';
          }
        }
      },
      fill: {
        type: 'image',
        opacity: 100,
        image: {
          width: 20,
          height: 20,
          src: chartImg,
        },

      },
      legend: {
        showForSingleSeries: show_legends,

        labels: {
          useSeriesColors: false,
        },
        markers: {
          // width: 5,
          // offsetY: 0,
          fillColors: selected_colorArr,
          // ['#00B050','#3489D8','#FF0000','#FF5B5B','#FFFF00','#0899EC','#E4CAF7','#FFC000','#00B050','#A90D99','#F6D673','#01E768','#D8C2E8'],
          // customHTML: [
          //   function() {
          //     return '<span><img width="10" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Visual_Studio_Code_Insiders_1.36_icon.svg/256px-Visual_Studio_Code_Insiders_1.36_icon.svg.png"></span>';
          //   }, function() {
          //     return '<span><img width="10" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Visual_Studio_Code_Insiders_1.36_icon.svg/256px-Visual_Studio_Code_Insiders_1.36_icon.svg.png"></span>';
          //   }
          // ]
          customHTML: legend_img,
        }
      },
      dataLabels: { 
        enabled: false
      },
      grid: {
        borderColor: this.theme == 'dark' ? ConstantConfig.chartBgColor.dark: ConstantConfig.chartBgColor.light,
      }
    }


    //show chart when data is available
    this.isActivityChartRender = true;
  }

  //create AVG chart
  createAvgRatingChart(percentage: number = 0) {
    this.avgChartOptions = {
      series: [percentage],
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
        background: 'transparent'
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#F2F2F2',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: '22px',
            },
          },
        },
      },
      grid: {
        borderColor: this.theme == 'dark' ? ConstantConfig.chartBgColor.dark: ConstantConfig.chartBgColor.light,
        padding: {
          top: -10,
        },
      },
      fill: {
        type: 'solid',
        colors: ['#FFD966'],
      },
      labels: [''],
      theme: {
        mode: this.theme == 'dark' ? 'dark' : 'light',
      },
    };
    this.isAvgChartRenderd = true;
  }

  /**
   * On Activity Days Dropdown selected
   * @param $e
   */
  onActivityGraphDDSelected($e: any) {
    const duration = $e.target.value;
    this.activityChartDurInDays = duration;
    this.isActivityChartRender = false;
    this.createActivityChart(this.activityList, duration);
  }

  // get loan detail
  getLTVChartData(id: Number) {
    const url = `${PathConfig.LTV_CHART}?loan_id=${id}`;
    this.httpService.get(url).subscribe((res) => {
      this.initCharts(res);
    });
  }

  /***
   * Get Queues Loan List
   */

  nextLoanIDCnter: number = 1;
  nextLoanTotalSize: number = 1;
  nextLoanDetail!: LoanDetail;
  flag: number = 0;
  collateral_type: any = ConstantConfig.COLLATERAL_TYPE;
  getQueuesLoanList(calledOn: string = '') {
    this.activityPageCnter = 1;
    const id = this.loanDetail.next_loan_key;
    this.loanID = id;
    const clientId = this.userService.getClientId();
    let url = `/home/servicing/queues-detail/${id}`;
    this.route.navigate([clientId + url], { queryParams: { qtype: this.queueType }, queryParamsHandling: "merge" });
    //  this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACTIVITY);

    const routeEvents = this.broadCastService
      .on(MsgConstantConfig.REFRESH_ACTIVITY)
      .subscribe((label: any) => {
      });
    this.eventSubscription.push(routeEvents);
  }

  convertLocalTimeZone(d:any)
  {
    if(d==''||d==null){return null}
    var testDateUtc = moment.tz(d,ConstantConfig.SERVER_TIMEZONE);
    return moment(testDateUtc).local().format("MM/DD/YYYY hh:mm:ss A");
  }

  loadingBarStarter() {
    if (this.loanInProgressStatus === true) {
      this.showLoanInProgressDialog();
      setTimeout(() => this.checkLoanProgressStatus(), 10000);
    }
  }

  showLoanInProgressDialog() {
    let showNext = true;
    const clientId = this.userService.getClientId();
    const modalRef = this.modalService.open(LoanInProgressModalComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm', keyboard: false, backdrop: 'static' });
    modalRef.componentInstance.refreshUrl = this.route.url;
    modalRef.componentInstance.queuesUrl = clientId + '/home/servicing/queues/assigned';
    if (this.is_next_Disable) {
      showNext = false;
    }
    else {
      const id = this.loanDetail.next_loan_key;
      let url = `/home/servicing/queues-detail/${id}`;
      const nextUrl = clientId + url;
      modalRef.componentInstance.nextUrl = nextUrl;
    }
    modalRef.componentInstance.showNext = showNext;

    modalRef.result.then((userResponse) => {
      if (userResponse) {
      }
    });
  }

  checkLoanProgressStatus() {
    this.loaderService.hideLoader();
    const url = `${PathConfig.LOANS}/${this.loanID}?sort_by=${this.sortBy}&sort_order=${this.orderBy}&limit=${this.pageLimit}&${this.queueType}=1&page=${this.currentPage}`;
    this.httpService.get(url, true).subscribe((response) => {
      if (response) {
        this.loaderService.hideLoader();
        this.loanInProgressStatus = response?.processing_status === 'IN_PROGRESS' ? true : false;
        if (this.loanInProgressStatus) {
          setTimeout(() => this.checkLoanProgressStatus(), 5000);
        }
        else {
          //this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_IN_PROGRESS_POP_UP,this.loanInProgressStatus);
          this.getLoanDetailByID(this.loanID);
          setTimeout(() => this.modalService.dismissAll(), 1000);
        }
      }
    }, (err) => {
      this.msgService.showError(err.error.message);
    }
    )
  }

  // get pmt chart detail
  getPmtChartData(id: Number) {
    const url = `${PathConfig.PMTS_CHART}?loan_id=${id}`;
    this.httpService.get(url).subscribe((res) => {
    let colorArr = [];
    // const xaxisLables = _.keys(_.groupBy(res.gap, 'date'));

      // create chart config
      // this.activityChartOptions = {
      //   series: [
      //     {
      //       name: res?.legend?.PMT,
      //       data: _.values(res.pmt).map((x)=>x.value.toFixed(2)),
      //     },
      //     {
      //       name:  res?.legend?.LIQ,
      //       data: _.values(res.liq).map((x)=>x.value.toFixed(2)),
      //     },
      //     {
      //       name:  res?.legend?.INS,
      //       data: _.values(res.ins).map((x)=>x.value.toFixed(2)),
      //     },
      //     {
      //       name:  res?.legend?.WNTY,
      //       data: _.values(res.wnty).map((x)=>x.value.toFixed(2)),
      //     },
      //     {
      //       name:  res?.legend?.GAP,
      //       data: _.values(res.gap).map((x)=>x.value.toFixed(2)),
      //     },
      //     {
      //       name:  res?.legend?.OTHER,
      //       data: _.values(res.other).map((x)=>x.value.toFixed(2)),
      //     }],
    const xaxisLables = res?.series || [];
    const chartData = res?.data || [];
      for(let i = 0;i<chartData?.length;i++){
        // switch (chartData[i]?.name) {
        switch (chartData[i]?.slug) {
          case 'PMT':
            colorArr.push('#00B050');
            break;
          case 'LIQ':
            colorArr.push('#D9CED6');

            break;
          case 'INS':
            colorArr.push('#0E86D4');

            break;
          case 'WNTY':
            colorArr.push('#FD7F20');

            break;
          case 'GAP':
            colorArr.push('#FF0000');

            break;
          case 'DEP':
            colorArr.push('#8B5F65');

            break;
          case 'BUYBACK':
            colorArr.push('#FF82AB');

            break;
          case 'GAP - Honored':
            colorArr.push('#FFF0F5');

            break;
          case 'ISP':
            colorArr.push('#C6E2FF');

            break;
          case 'SETBAL':
            colorArr.push('#00F5FF');

            break;
          // case 'GAP':
          //   colorArr.push('#FF0000');

          // break;
          // case 'GAP':
          //   colorArr.push('#FF0000');

          // break;
          // case 'GAP':
          //   colorArr.push('#FF0000');

          // break;
          // case 'GAP':
          //   colorArr.push('#FF0000');

          // break;
          // case 'GAP':
          //   colorArr.push('#FF0000');

          // break;
          case 'OTHER':
            colorArr.push('#F2E34C');
            break;
          default:
            // colorArr.push(this.colorArr[i+3]);
            const randomColor = Math.floor(Math.random() * 16777215).toString(16);
            colorArr.push('#' + randomColor)
            break;
        }
      }
      // create chart config
      this.activityChartOptions = {
        series: chartData,
        chart: {
          height: 200,
          type: 'bar',
          stacked: true,
          background: 'transparent',
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            rotate: 90,

            dataLabels: {
              total: {
                enabled: false,
                style: {
                  fontSize: '7px',
                  fontWeight: 900,

                },
                rotate: 90,
              }
            }
          },
        },
        // xaxis: {
        //   tickAmount: res.other.length,
        //   type: 'numeric',
        //   tickPlacement: 'on',
        //   title: {
        //     text: 'Years',
        //   },

        //   labels: {
        //     rotate: 270,
        //     rotateAlways: true,
        //     formatter: function (value: any, timestamp: any) {
        //       return moment(timestamp).format('MM/YY');
        //     },
        //   },
        // },
        xaxis: {
          // tickAmount:xaxisLables.length,
          // type: 'datetime',
          // title: {
          //   text: 'Years',
          // },
          labels: {
            rotateAlways: true,
            rotate: 270,
          },
          //   formatter: function(value:string) {
          //     return xaxisLables;
          //     //return value;
          //   }
          // },
          categories: xaxisLables,
          tooltip: {
            enabled: false,
          }
        },

        yaxis: {
          tickAmount: 5,
          labels: {
            showDuplicates: false,
            minWidth: 40,
            formatter: function (value: number) {
              return '$' + value.toFixed(2);
            },
          },
          title: {
            minWidth: 40,
            text: 'PAYMENTS',
          },
        },
        colors: colorArr,
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
          strokeWidth: 0,
          colors: colorArr,
        },
        theme: {
          mode: this.theme == 'dark' ? 'dark' : 'light',
        },
        // tooltip: {
        //   custom: function(value: any, timestamp: any, dataPointIndex:any) {
        //     let id =chartdata[value?.seriesIndex].data[value?.dataPointIndex][2]??'';
        //     let data =  act_data?.find((item: any)=> item?.id == id);

        //     let agent_firstname=data?.activity_note_agent?.firstname??'System';
        //     let agent_lastname=data?.activity_note_agent?.lastname??'';
        //     let note=data?.note?.length < 100 ? data?.note : data?.note?.substring(0,100)+' ......';
        //     note = note? note:'';
        //   let d=moment(moment.utc(data?.activity_at))?.local()?.format("MM/DD/YYYY hh:mm:ss A") ??'';
        //     return '<div style="padding:20px" align="left"><b>Type</b>: ' +data?.activity_type + '<br/>' +
        // '<b>Activity Note</b>: ' + note + '<br/>' +
        // '<b>Agent </b>: ' +agent_firstname +" "+agent_lastname
        // + '<br/>' +
        // '<b>Date</b>: ' + d + '<br/>' +
        // '</div>';
        //   }
        // },
        // fill: {
        //   type: 'image',
        //   opacity: 1,
        //   image: {
        //     src: chartImg,
        //     width: 20,
        //     height: 20,
        //   },
        // },
        legend: {
          showForSingleSeries: true,

          labels: {
            useSeriesColors: false,
          },
          markers: {
            fillColors: colorArr,
            // customHTML: [
            //   function() {
            //     return '<span><img width="10" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Visual_Studio_Code_Insiders_1.36_icon.svg/256px-Visual_Studio_Code_Insiders_1.36_icon.svg.png"></span>';
            //   }, function() {
            //     return '<span><img width="10" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Visual_Studio_Code_Insiders_1.36_icon.svg/256px-Visual_Studio_Code_Insiders_1.36_icon.svg.png"></span>';
            //   }
            // ]
          },
          // fill: {
          //   opacity: 1
          // }
        },
        grid: {
          borderColor: this.theme == 'dark' ? ConstantConfig.chartBgColor.dark: ConstantConfig.chartBgColor.light,
        }

      };
    });
  }

  // get bucket chart detail
  getBucketChartData(id: Number) {
    const url = `${PathConfig.BUCKET_CHART}?loan_id=${id}`;
    this.httpService.get(url).subscribe((res) => {
    // const xaxisLables = _.keys(_.groupBy(res.active_dq_bucket, 'date'));
    let colorArr = [];
    const xaxisLables = res?.series || [];
    const chartData = res?.data || [];
    let max_number = [];
    let min_number = [];
    // let chart_data_clone=  _.cloneDeep(chartData)??[];

      for (let i = 0; i < chartData?.length; i++) {
        switch (chartData[i]?.slug) {
          case 'active_dq_bucket':
            // for(let j = 0; j<chartData[i]?.data?.length;j++){
            //   chart_data_clone[i].data[j]= Math.abs(chart_data_clone[i]?.data[j]);
            // }
            max_number.push(Math.max(...(chartData[i]?.data)));
            min_number.push(Math.min(...(chartData[i]?.data)));
            colorArr.push('#FFFF00');
            break;
          case 'deficiency_dq_bucket':
            // for(let j = 0; j<chartData[i]?.data?.length;j++){
            //   chart_data_clone[i].data[j]= Math.abs(chart_data_clone[i]?.data[j]);
            // }
            max_number.push(Math.max(...(chartData[i]?.data)));
            min_number.push(Math.min(...(chartData[i]?.data)));
            colorArr.push('#FF0000');
            break;
          default:
            // for(let j = 0; j<chartData[i]?.data?.length;j++){
            //   chart_data_clone[i].data[j]= Math.abs(chart_data_clone[i]?.data[j]);
            // }
            max_number.push(Math.max(...(chartData[i]?.data)));
            min_number.push(Math.min(...(chartData[i]?.data)));
            colorArr.push('#00B050');
            break;
        }
      }

      // let tick_number =  Math.max(...(max_number))
      let max_limit = Math.max(...(max_number));
      max_limit = max_limit >= 5 ? max_limit : 5;
      let min_limit = Math.min(...(min_number));
      min_limit = min_limit <= -5 ? min_limit : -5;

      // create chart config
      this.activityChartOptions = {
        // series: [
        //   {
        //     name: 'Active DQ Bucket',
        //     data: _.values(res?.active_dq_bucket).map((x)=>x?.value),
        //   },
        //   {
        //     name:  'Deficiency DQ Bucket',
        //     data: _.values(res?.deficiency_dq_bucket).map((x)=>x?.value),
        //   }],
        series: chartData,
        chart: {
          height: 200,
          type: 'bar',
          stacked: true,
          background: 'transparent',
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            rotate: 90,

            dataLabels: {
              total: {
                enabled: false,
                style: {
                  fontSize: '7px',
                  fontWeight: 900,

                },
                rotate: 90,
              }
            }
          },
        },

        xaxis: {

          labels: {
            rotate: 270,
            rotateAlways: true,
          },

          categories: xaxisLables,
          tooltip: {
            enabled: false,
          }
        },

        yaxis: {
          forceNiceScale: true,
          // tickAmount: tick_number = tick_number>=3? 3:tick_number,
          min: min_limit || -5,
          max: max_limit || 5,
          decimalsInFloat: 0,
          labels: {
            showDuplicates: false,
            minWidth: 40,
            formatter: function (value: number) {
              return value?.toFixed(0);
            },
          },
          title: {
            minWidth: 40,
            text: 'DQ BUCKET',
          },
        },
        colors: colorArr,
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
          strokeWidth: 0,
          colors: colorArr,
        },
        theme: {
          mode: this.theme == 'dark' ? 'dark' : 'light',
        },
        legend: {
          showForSingleSeries: true,

          labels: {
            useSeriesColors: false,
          },
          markers: {
            fillColors: colorArr,
          }
        },
        grid: {
          borderColor: this.theme == 'dark' ? ConstantConfig.chartBgColor.dark: ConstantConfig.chartBgColor.light,
        }

      };
    });
  }

  chartTypeChange(e: any) {
    this.activityChartOptions = [];
    //  let chart_type= document.getElementById('chart_type');
    let chart_type= e.target.value
    if(chart_type)
    this.selectedChart = chart_type;
    switch (chart_type) {
      case 'pmts':
        this.getPmtChartData(this.loanID);
        break;
      case 'buckets':
        this.getBucketChartData(this.loanID);
        break;
      default:
        this.createActivityChart(this.activityList, this.activityChartDurInDays)
        break;
    }
  }

  transactionLoadingBarStarter(){
    if(this.loanDetail?.any_transaction_in_bg_progress && this.showProgressBar ){
      this.openTransactionProcessingModal();
      setTimeout(() => this.checkLoanTransactionProgressStatus(), 9000);
    }
    else{
      this.closeLoadingbar();
    }
  }

  checkLoanTransactionProgressStatus() {
    this.loaderService.hideLoader();
    const url = `${PathConfig.PROCESSING_STATUS}?query_type=${"TRANSACTION"}&loan_id=${this.loanID}`;
    this.httpService.get(url, true).subscribe((response) => {
      if(response){
        this.loaderService.hideLoader();
        // this.loanInProgressStatus = response?.processing_status === 'IN_PROGRESS' ? true : false;
        if (response?.processing_status) {
          this.processingTransactionId = response?.task_in_progress[0]?.primary_id;
          this.localStorageService.set('processing_transaction_id', this.processingTransactionId);
          setTimeout(() => this.checkLoanTransactionProgressStatus(), 9000);
        }
        else {
          this.checkTransactionProcessingStatus();
          this.broadCastService.broadcast(MsgConstantConfig.SHOW_HIDE_CLIENT_PROGRESS_BAR, true);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACTIVITY);
          this.closeLoadingbar();
          this.reloadWithoutWhiteScreen();
          setTimeout(()=> this.modalService.dismissAll(),1000);
        }
      }
    }, (err) => {
      this.closeLoadingbar();
      this.msgService.showError(err.error.message);
    }
    )
  }
  reloadWithoutWhiteScreen() {
    const currentUrl:any = this.route.url;
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.navigateByUrl(currentUrl).then(() => {
      this.route.navigate(currentUrl);
    });
  }

  openTransactionProcessingModal() {
    if (this.loanDetail?.any_transaction_in_bg_progress) {
      this.loaderService.hideLoader();
      this.modalRef = this.modalService.open(LoadingBarModalComponent, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'draggable-container',
        backdrop: false,
        keyboard: false,
        size: 'md',
      });
      this.modalRef.componentInstance.draggable = true;
      this.modalRef.componentInstance.message = this.translateService.instant('Processing Transaction');
      this.modalRef.componentInstance.message2 = this.translateService.instant('Please wait while we cascade and verify the Transaction List...');
    }
    else{
      this.modalRef.componentInstance.time_bar = false;
    }
  }

  closeLoadingbar(){
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  checkTransactionProcessingStatus() {
    let processing_transaction_id = localStorage.getItem('processing_transaction_id')
    if (processing_transaction_id) {
      const url = `${PathConfig.LOAN_TRANSACTIONS}/${processing_transaction_id}`;
      this.httpService.get(url, true).subscribe((response) => {
        if(response){
          let resp = response?.bg_response;
          resp = JSON.parse(resp);
          if (resp?.status) {
            this.msgService.showSuccess(resp?.message);
          }
          else {
            this.msgService.showError(resp?.error ?? resp?.message);
          }
          this.localStorageService.remove('processing_transaction_id');
          this.loaderService.hideLoader();
        }
      })
    }

  }

  getLoanIdToCopy() {
    this.clipboard.copy(this.loanDetail?.loan_id);

    this.msgService.showSuccess(this.translateService.instant('Loan ID copied to clipboard'));

  }

  ngOnDestroy(): void {

    this.destroy$.next();
    this.destroy$.complete();
    this.cdr.detectChanges();
    this.eventSubscription.forEach((event) => event.unsubscribe());

  }

  async getLoanInfo(loanID: any): Promise<any> {
    // Implement your logic to fetch loan details here
    // Replace with your actual implementation
    return new Promise((resolve) => {
      setTimeout(async () => {
        // Perform the broadcast
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);

        // Ensure the broadcast is complete before resolving the promise
        await this.waitForBroadcastToComplete();

        // Return the loan details
        resolve(this.loanDetail);
      }, 1000); // Adjust timeout as needed
    });
  }


  // This function simulates waiting for the broadcast to complete
waitForBroadcastToComplete(): Promise<void> {
  return new Promise((resolve) => {
    // Simulate a delay or check if the broadcast has been fully processed
    setTimeout(() => resolve(), 500); // Adjust timeout as needed
  });
}
}
