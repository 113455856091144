
import { Component, EventEmitter, forwardRef, Input, Optional, Output, Provider, Self, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor,FormControl,NgControl,NG_VALIDATORS,NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import {NgbDateStruct,NgbDateParserFormatter, NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ConstantConfig } from 'src/app/core/config/constant.config';


const DT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomDatePickerComponent),
  multi: true,
};

const DT_CONTROL_VALIDATOR:Provider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CustomDatePickerComponent),
    multi: true
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './custom-date-picker.component.html',
  providers: [DT_CONTROL_VALUE_ACCESSOR,DT_CONTROL_VALIDATOR]
})
export class CustomDatePickerComponent implements ControlValueAccessor {
  @Input()  placeholder:string = 'mm/dd/yyyy';
  @Output() dateSelected: EventEmitter<any> = new EventEmitter();
  @Input() date: string = '';
  @Input() futureDate: boolean = true;
  @Input() pastDate: boolean = true;
  @Input() thirtyDaysLimit:boolean = false;
  @Input() maxDateLimit = 29;
  @Input() minDateLimit = 90;
  @Input() ninetyDaysPastLimit:boolean = false;
  @Input() contractDate:any = '';

  maxDate : any = '';
  minDate : any = {year: 1920, month: 1, day: 1};
  selected!: any;
  disabled = false;
  onChange: any = () => { };
  onTouched: any = () => { };
  onValidationChange: any = () => {};


  constructor(
    private dtAdapter: NgbDateAdapter<string>,
    private dtParser:NgbDateParserFormatter){
  }

  ngOnInit(): void {

      //set default max date 10 year in future
      let date = new Date();
      let todayYear = date.getFullYear();
      let todayMonth = date.getMonth();
      let todayDate = date.getDate();
      this.maxDate = {year: todayYear+10, month: todayMonth, day: todayDate};

    console.log(this.contractDate);
      if(this.futureDate == false){
        let date = new Date();
        let todayYear = date.getFullYear();
        let todayMonth = date.getMonth();
        let todayDate = date.getDate();
        this.maxDate = {year: todayYear, month: todayMonth+1, day: todayDate};
      }
      if(this.thirtyDaysLimit == true){
        let date = new Date(new Date().setDate(new Date().getDate() + 29));
        let todayYear = date.getFullYear();
        let todayMonth = date.getMonth();
        let todayDate = date.getDate();
        this.maxDate = {year: todayYear, month: todayMonth+1, day: todayDate};
      }
      if(this.ninetyDaysPastLimit == true){
        let date = new Date(moment(this.contractDate, 'YYYY-MM-DD').subtract(90,'days').format());
        // let date = new Date(new Date().setDate(new Date(this.contractDate).getDate() - 90));
        console.log(date)
        let todayYear = date.getFullYear();
        let todayMonth = date.getMonth();
        let todayDate = date.getDate();
        console.log(todayYear)
        this.minDate = {year: todayYear, month: todayMonth+1, day: todayDate};
      }
      if(this.pastDate == false){
        let date = moment(new Date())?.tz(ConstantConfig.SERVER_TIMEZONE);
        let todayYear = date.year();
        let todayMonth = date.month();
        let todayDate = date.date();
        this.minDate = {year: todayYear, month: todayMonth+1, day: todayDate};
      }
  }

  get value() {
    return this.selected;
  }

  set value(value: any) {
    this.selected = this.dtAdapter.toModel(this.dtAdapter.fromModel(value)) ?? '';

    this.onChange(this.selected);
    this.onValidationChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    const date = changes['date'] || [];
    if (date) {
      if (date['currentValue'] !== date['previousValue'] && date['firstChange']) {
        this.selected = this.dtAdapter.toModel(this.dtAdapter.fromModel(date['currentValue'])) ?? '';
        this.onChange(this.selected);
      }
    }
  }

  onDateSelect(dt: NgbDateStruct) {
    this.onTouched();
    this.selected = this.dtAdapter.toModel(dt) ?? '';
    this.onChange(this.selected);
    this.selected ? this.dateSelected.emit(this.selected) : '';
  }

  writeValue(value: any): void {
    // if(value){
      this.selected = this.dtAdapter.toModel(this.dtAdapter.fromModel(value)) ?? '';
      this.onChange(this.selected);
    // }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): any {
  //  if((control && control.hasValidator(Validators.required))){

  //  }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  onBlur(status:any){
    if(status == 'INVALID'){
      this.selected =  '';
      this.onChange(this.selected);
    }else{
      this.onChange(this.selected);
    }
    this.onTouched();
    this.dateSelected.emit(this.selected);
  }
}
