<!-- Kickback Repo Review Modal -->
<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
  <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
<form #f="ngForm" [formGroup]="form" novalidate>
    <div class="modal-header align-items-center " >
      <h6 class="modal-title modal-title-yellow">{{'Kickback Repo Review'|translate}}</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0">
      <div class="lms-module-card">
        <div class="card-body">
          <div class="card-form-content card-form-yellow">
            <div class="bg-note-info p-2 mb-3">
              {{'Please input kickback reason and confirm before submitting'|translate}}.
            </div>
            <div class="table-responsive">
              <table class="table table-sm table-no-bg table-align-middle">
                <thead>
                  <tr>
                    <td class="border-0">{{'Field Name'|translate}}</td>
                    <td class="border-0">{{'Before'|translate}}</td>
                    <td class="border-0"></td>
                    <td class="border-0">{{'After'|translate}}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{'Sub-Status'|translate}}</td>
                    <td>
                      <!-- {{ (loanDetail.latest_kickback_repo_review?.before?.sub_status!=null && loanDetail.latest_kickback_repo_review?.before?.sub_status!='')? conver_To_Camel_Case( loanDetail.latest_kickback_repo_review?.before?.sub_status.replace('_',' ')) : 'Repo Review'}}
                     -->
                     {{ kickbackRepoReviewPreviewData?.before?.sub_status || '-'}}

                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td class="lms-text-light-purple">{{ kickbackRepoReviewPreviewData?.after?.sub_status || '-'  }}</td>
                  </tr>
                  <tr>
                    <td>{{'Assigned To'|translate}}</td>
                    <td>
                      <!-- {{ (this.loanDetail.latest_kickback_repo_review?.before?.user?.employee_initials==null || this.loanDetail.latest_kickback_repo_review?.before?.user?.employee_initials=="")?this.after_assignee_name:this.before_assignee_name  }}
                     -->
                     {{ kickbackRepoReviewPreviewData?.before?.user || '-'   }}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />                    </td>
                    <td class="lms-text-light-purple">{{ kickbackRepoReviewPreviewData?.after?.user || '-'   }}</td>
                  </tr>
                  <tr>
                    <td>{{'Last Kickback Date'|translate}}</td>
                    <td>
                      <!-- {{convertLocalTimeZone(loanDetail.latest_kickback_repo_review?.updated_at)}} -->
                      {{ convertLocalTimeZoneWithoutTime(kickbackRepoReviewPreviewData?.before?.last_repo_review_date ) || '' }}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />                    </td>
                    <td class="lms-text-light-purple">{{ convertLocalTimeZoneWithoutTime(kickbackRepoReviewPreviewData?.after?.last_repo_review_date ) || '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-group">
              <label> {{'Kickback Reason'|translate}}: </label>
              <textarea formControlName="remark" name="remark" [value]="afterInfo?.remark" rows="4" class="form-control"></textarea>
            </div>
            <val-errors controlName="remark">
                <ng-template valError="required">
                    <span class="d-block font-size-14 lms-text-light-pink">{{'Cannot leave kickback reason blank'|translate}}.</span>
                </ng-template>
              </val-errors>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <div>
        <span class="d-block font-size-14 lms-text-light-pink" *ngIf="!iskickBackPermission">{{"You don't have the permission to kickback repo review"|translate}}.</span>
      </div>
      <div>
        <button type="button" class="btn btn-warning btn-150" (click)="onFormSubmit()" *ngIf="iskickBackPermission">
          {{'Submit'|translate}}
        </button>
        <button type="button" class="btn btn-pink btn-150" (click)="openGetApprovalModal(GetApprovalModal)" *ngIf="!iskickBackPermission">
          {{'Get Approval'|translate}}
        </button>
      </div>
    </div>
</form>
</div>
</div>
<!-- Override Modal -->
<ng-template #GetApprovalModal let-modal>
  <div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
    <div class="modal-body pb-0 px-0">
      <h6 class="modal-title text-center mb-3" >{{'Get Approval'|translate}}</h6>
      <div class="lms-module-card">
        <div class="card-body">
          <div class="card-form form-vertical">
            <div class="form-group">
              <label class="form-group-label">{{'Initials'|translate}}</label>
              <input type="text" name="From" class="form-control form-control-sm" value="EB1" />
            </div>
            <div class="form-group">
              <label class="form-group-label">{{'PIN'|translate}}</label>
              <input type="text" name="pin" class="form-control form-control-sm" value="212121" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-block" (click)="modal.close('Close click')">
        {{'Get Approval'|translate}}
      </button>
    </div>
    </div>
    </div>
  </ng-template>
