import { Component, Input, OnInit, SimpleChanges, Renderer2, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { TranslateService } from '@ngx-translate/core';
import { QueueDetailServiceService } from '../servicing/queues/queue-detail/queue-detail-service.service';
import { Subscription } from 'rxjs';
import { BroadcastService, HttpService, MessageService, UserService } from 'src/app/core';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { ActivatedRoute, Router } from '@angular/router';
import { PathConfig } from 'src/app/core/config/path.config';
import { ClientDetailService } from 'src/app/core/services/client-detail-service';
import { param } from 'jquery';
import { error } from 'console';

@Component({
  selector: 'app-caller',
  templateUrl: './caller.component.html',
  styleUrls: ['./caller.component.scss']
})
export class CallerComponent implements OnInit {
  eventSubscription: Array<Subscription> = [];
  @Input() maskOff: boolean = false;
  @Input() dailing_no?: any;
  call!: UntypedFormGroup;
  isDirty: boolean = false;
  dailing_number?: any;
  US_PHONE_MASKING = ConstantConfig.US_PHONE_MASKING;
  subscription: any;
  loanDetail: any;
  dropdownOpen: boolean = false;
selectedContact: string | null = null;
selectedValue: string | null = null;
  // {
  //   applicant: {
  //     id: 10011,
  //     firstname: 'Jennifer',
  //     middlename: 'Michael',
  //     lastname: 'Brown',
  //     // other applicant properties
  //   },
  //   co_applicant: {
  //     // Uncomment and fill in if there is a co-applicant
  //     id: 10012,
  //     firstname: 'John',
  //     middlename: 'A.',
  //     lastname: 'Doe',
  //     // other co-applicant properties
  //   },
  //   // ... rest of your loanDetail object
  // };
  contactList: any[] = [];
  loanID: any;
  isLoanApiPatching: boolean = false;
  selecteduser: any;
  selectedMainStatus: any;
  selected_sub_status: any;
  selectedpriority: any;
  pri: any;
  selectedPortfolio: any;
  isAvgChartRenderd: boolean = false;
  clientInfo = {
    name: '',
    // type: '',
  };
  queueType: any;
  sortBy: any;
  orderBy: any;
  pageLimit: any;
  currentPage: any;
  sectionTitle: any;
  isAssestRecoverySection: boolean = false;

  selectedPhoneNumber: any;
  selectedApplicantId: any;
  isDNC: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private msgService: MessageService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private clientDetailService: ClientDetailService,

    private httpService: HttpService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {

    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');

    this.clientInfo.name = this.clientDetailService.getClientInfo('name');
    // this.clientInfo.type =
    //   this.clientDetailService.getClientInfo('is_default') == 'NO'
    //     ? 'Dealership'
    //     : 'Client';
    


    const routeEvents = this.activatedRoute.queryParams
      .subscribe(params => {

        this.queueType = params['qtype'] == null ? "" : params['qtype'];
        this.sortBy = params['sortby'] == null ? "" : params['sortby'];
        this.orderBy = params['orderby'] == null ? "" : params['orderby'];
        this.pageLimit = params['limit'] == null ? "" : params['limit'];
        this.currentPage = params['page'] == null ? "" : params['page'];

      });

    this.eventSubscription.push(routeEvents);
    this.loanID = Number(this.route.url.split('/')[5]);
    if (this.loanID) {
      this.getLoanDetailByID(this.loanID);
    }

    //   const routeEvents1= this.broadCastService
    //   .on(MsgConstantConfig.QUEUE_DETAIL_SEC_HEADING)
    //   .subscribe((label: any) => {
    //     this.sectionTitle = label;
    //     this.isAssestRecoverySection = label == 'Asset Recovery' ? true : false;

    //   });
    //   this.eventSubscription.push(routeEvents1);
    // const routeEvents2= this.broadCastService.on(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA)
    //   .subscribe(() => {

    //     if (this.loanID) {

    //       this.getLoanDetailByID(this.loanID);
    //     }
    //   });
    //   this.eventSubscription.push(routeEvents2);

  }

  ngOnInit(): void {
    this.call = new UntypedFormGroup({
      dailing_number: new UntypedFormControl('', [Validators.required]),
    })
    if (this.contactList && this.contactList.length > 0) {
      const firstContact = this.contactList[0];
      this.selectedPhoneNumber = firstContact.home_phone; // defaulting to Home Phone
    }
    this.call.valueChanges.subscribe((e) => (this.isDirty = true));
    this.getdailingnumber();
  }
  getdailingnumber() {
    this.dailing_no != null ? this.dailing_no : '';
  }

  // get loan detail
  getLoanDetailByID(id: Number) {
    this.isLoanApiPatching = true;
    this.selecteduser = this.userService.getUserInfo("id");


    this.activatedRoute.queryParams
      .subscribe(params => {
        let priority_status = params['account_work_activity_priority_status'];
        this.selectedMainStatus = [null, '', undefined].includes(params['loan_status']) ? null : params['loan_status'];
        this.selected_sub_status = [null, '', undefined].includes(params['loan_sub_status_label']) ? null : params['loan_sub_status_label'];
        this.selecteduser = [null, undefined].includes(params['user_id']) ? this.selecteduser?.toString() : params['user_id']?.toString();
        this.selectedpriority = [null, '', undefined].includes(params['account_work_activity_priority_status']) ? null : priority_status?.split(',');
        this.pri = this.selectedpriority?.length > 0 ? this.selectedpriority?.toString() : '';
        this.selectedPortfolio = [null, '', undefined].includes(params['portfolio_id']) ? null : params['portfolio_id'];
      })

    // this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
    this.isAvgChartRenderd = false;
    const url = `${PathConfig.LOANS}/${id}?sort_by=${this.sortBy}&sort_order=${this.orderBy}&limit=${this.pageLimit}&${this.queueType}=1&page=${this.currentPage}&account_work_activity_priority_status=${this.pri ?? ''}&user_id=${this.selecteduser}&loan_status=${this.selectedMainStatus ?? ''}&loan_sub_status_label=${this.selected_sub_status ?? ''}&portfolio=${this.selectedPortfolio ?? ''}`;
    this.httpService.get(url).subscribe((res: any) => {

      this.loanDetail = res;
      this.populateContactList();
    })

  }
  populateContactList(): void {
    // Clear the contactList in case this method is called multiple times
    this.contactList = [];
    console.log("All loan Details", this.loanDetail);

    // Add Applicant
    if (this.loanDetail.applicant) {
      this.contactList.push(this.loanDetail.applicant);
    }

    // Add Co-Applicant
    if (this.loanDetail.co_applicant) {
      this.contactList.push(this.loanDetail.co_applicant);
    }

    // Add Co-Signers
    if (this.loanDetail.co_signers && this.loanDetail.co_signers.length > 0) {
      this.loanDetail.co_signers.forEach((coSigner: any) => {
        this.contactList.push(coSigner);
      });
    }

    // Add Recipient
    if (this.loanDetail.recipient) {
      this.contactList.push(this.loanDetail.recipient);
    }

    // Add Company
    if (this.loanDetail.company) {
      const company = {
        applicant_type: 'COMPANY',
        id: this.loanDetail.company.id,
        firstname: this.loanDetail.company.company_name,
        lastname: '',
        mobile_phone: this.loanDetail.company.mobile_phone,
        home_phone: this.loanDetail.company.home_phone,
        work_phone: this.loanDetail.company.work_phone
      };
      this.contactList.push(company);
    }

    console.log("contactList", this.contactList);

    // Handle default case by checking available phone types (mobile, home, work)
    if (this.contactList && this.contactList.length > 0) {
      const firstContact = this.contactList[0];
      this.selectedApplicantId = firstContact.id;

      // Set the default phone number by checking in the order: mobile -> home -> work
      if (firstContact.mobile_phone) {
          this.selectedPhoneNumber = firstContact.mobile_phone;
          this.setDNCStatus(firstContact, 'mobile');
      } else if (firstContact.home_phone) {
          this.selectedPhoneNumber = firstContact.home_phone;
          this.setDNCStatus(firstContact, 'home');
      } else if (firstContact.work_phone) {
          this.selectedPhoneNumber = firstContact.work_phone;
          this.setDNCStatus(firstContact, 'work');
      } else {
          this.selectedPhoneNumber = undefined; // No phone available
          this.isDNC = false; // No DNC check necessary
      }
    }
}

// Utility function to set the DNC status based on contact type and phone type
setDNCStatus(contact: any, phoneType: string): void {
    // Reset DNC status before setting it again
    this.isDNC = false;

    if (phoneType === 'mobile') {
        this.isDNC = (contact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_mobile_calling_a1 === 'TRUE') ||
                     (contact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_mobile_calling_a2 === 'TRUE') ||
                     (contact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_mobile_calling_c1 === 'TRUE') ||
                     (contact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_mobile_calling_c2 === 'TRUE') ||
                     (contact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_mobile_calling_c === 'TRUE') ||
                     (contact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_mobile_calling_r === 'TRUE');
    } else if (phoneType === 'home') {
        this.isDNC = (contact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_home_phone_calling_a1 === 'TRUE') ||
                     (contact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_home_phone_calling_a2 === 'TRUE') ||
                     (contact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_home_phone_calling_c1 === 'TRUE') ||
                     (contact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_home_phone_calling_c2 === 'TRUE') ||
                     (contact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_home_phone_calling_c === 'TRUE') ||
                     (contact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_home_phone_calling_r === 'TRUE');
    } else if (phoneType === 'work') {
        this.isDNC = (contact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_work_phone_calling_a1 === 'TRUE') ||
                     (contact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_work_phone_calling_a2 === 'TRUE') ||
                     (contact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_work_phone_calling_c1 === 'TRUE') ||
                     (contact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_work_phone_calling_c2 === 'TRUE') ||
                     (contact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_work_phone_calling_c === 'TRUE') ||
                     (contact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_work_phone_calling_r === 'TRUE');
    }
}

  onPhoneSelect(event: any) {
    const selectedValue = event.target.value; // e.g., "10028-mobile"
    const [applicantId, phoneType] = selectedValue.split('-');
    this.selectedApplicantId = applicantId;
    // Find the selected contact by applicantId
    const selectedContact = this.contactList.find(contact => contact.id === Number(applicantId));

    if (selectedContact) {
        // Reset DNC flag before setting it again
        this.isDNC = false;

        // Check for mobile, home, or work phone based on the selected option
        if (phoneType === 'mobile') {
            this.selectedPhoneNumber = selectedContact.mobile_phone;

            // Check DNC for APPLICANT and CO-APPLICANT
            this.isDNC = (selectedContact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_mobile_calling_a1 === 'TRUE') ||
                         (selectedContact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_mobile_calling_a2 === 'TRUE') ||
                         (selectedContact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_mobile_calling_c1 === 'TRUE') ||
                         (selectedContact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_mobile_calling_c2 === 'TRUE') ||
                         (selectedContact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_mobile_calling_c === 'TRUE') ||
                         (selectedContact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_mobile_calling_r === 'TRUE');
        } else if (phoneType === 'home') {
            this.selectedPhoneNumber = selectedContact.home_phone;
            this.isDNC = (selectedContact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_home_phone_calling_a1 === 'TRUE') ||
                         (selectedContact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_home_phone_calling_a2 === 'TRUE') ||
                         (selectedContact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_home_phone_calling_c1 === 'TRUE') ||
                         (selectedContact.applicant_type === 'APPLICCO-SIGNER-2ANT' && this.loanDetail.dnc_list_home_phone_calling_c2 === 'TRUE') ||
                         (selectedContact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_home_phone_calling_c === 'TRUE') ||
                         (selectedContact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_home_phone_calling_r === 'TRUE');
        } else if (phoneType === 'work') {
            this.selectedPhoneNumber = selectedContact.work_phone;

            // Check DNC for APPLICANT and CO-APPLICANT
            this.isDNC = (selectedContact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_work_phone_calling_a1 === 'TRUE') ||
                         (selectedContact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_work_phone_calling_a2 === 'TRUE') ||
                         (selectedContact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_work_phone_calling_c1 === 'TRUE') ||
                         (selectedContact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_work_phone_calling_c2 === 'TRUE') ||
                         (selectedContact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_work_phone_calling_c === 'TRUE') ||
                         (selectedContact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_work_phone_calling_r === 'TRUE') ;
        }
    }
}

  formatName(person: any): string {
    return `${person.firstname} ${person.middlename ? person.middlename + ' ' : ''}${person.lastname}`;
  }
  phoneCallStart() {
    if (this.selectedApplicantId && this.selectedPhoneNumber) {
      const url = `${PathConfig.MAKE_CALL}`;
      const param = {
        applicant_id: this.selectedApplicantId, 
        phone_number: this.selectedPhoneNumber 
      };

      this.httpService.post(url, param).subscribe(
        (response) => {
          this.msgService.showSuccess(response?.message);
        },
        (error) => {
          this.msgService.showError(error.error.message);
        }
      );
    } else {
      this.msgService.showError('Please select a contact and phone number.');
    }
  }
  


}
