<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
  <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
<ng-container>
    <div class="modal-header align-items-center" >
      <h6 class="modal-title modal-title-success">{{'Manual Charge Off'|translate}}</h6>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0" >

      <div class="lms-module-card">
        <div class="card-body">
          <div class="card-form-content card-form-success">
            <div class="bg-note-info p-2 mb-3">
                {{'Click submit to manually charge off the loan'|translate}}.
            </div>
            <div class="table-responsive">
              <table class="table table-sm table-no-bg table-align-middle mb-0">
                <thead>
                  <tr>
                    <td class="border-0">{{'Field Name'|translate}}</td>
                    <td class="border-0">{{'Before'|translate}}</td>
                    <td class="border-0"></td>
                    <td class="border-0">{{'After'|translate}}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{'Status'|translate}}</td>
                    <td>{{(loanDetail?.status=='ACTIVE' ? 'Active' : '')|translate }}</td>
                    <td>
                      <img class="img-fluid" src="assets/images/icons/arrow2.png" alt="" />
                    </td>
                    <td class="">
                    {{'Charge-Off'|translate}}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <div>

      </div>
      <div>
        <button *ngIf="manualChargeOffLoans" type="button" class="btn btn-success-light btn-150" (click)="submit()">
          {{'Submit'|translate}}
        </button>

      </div>
    </div>

  </ng-container>
  </div>
  </div>

  <!-- Override Modal
<ng-template #GetApprovalModal let-modal>
<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
  <div class="modal-header " >
    <h6 class="modal-title text-center mb-3">Get Approval</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form #f="ngForm" [formGroup]="appform" (ngSubmit)="verifyPin()" novalidate>
  <div class="modal-body pb-0 px-0">


    <div class="lms-module-card">
      <div class="card-body">
        <div class="card-form form-vertical">
          <div class="form-group">
            <label class="form-group-label">Initials</label>
            <input type="text" name="employee_initials" formControlName="employee_initials" class="form-control form-control-sm" value="EB1" />
            <val-errors controlName="employee_initials">
              <ng-template valError="required">Initial is required</ng-template>
            </val-errors>
          </div>
          <div class="form-group">
            <label class="form-group-label">PIN</label>
            <input type="password" name="pin" formControlName="pin" class="form-control form-control-sm" value="212121" />
            <val-errors controlName="pin">
              <ng-template valError="required">PIN is required</ng-template>
            </val-errors>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" (click)="this.appform.valid ?  modal.dismiss('Cross click') : ''" class="btn btn-primary btn-block">
      Get Approval
    </button>
  </div>
</form>
</div>
</div>
</ng-template> -->
