import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { PathConfig } from 'src/app/core/config/path.config';
import { UserDetail } from 'src/app/shared/models/user/user-detail.model';
import * as _ from 'lodash';
import { Utility } from 'src/app/core/utils/utility';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ValidateNumber } from 'src/app/core/validators/number.validator';
import { LoanTransactionDetail } from 'src/app/shared/models/loan/loan.transaction.model';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-repo-review',
  templateUrl: './repo-review.component.html',
  styleUrls: ['./repo-review.component.scss']
})
export class RepoReviewComponent implements OnInit {

  @Input() loanDetail!: any;
  @Input() loanID!: any;
  submitted: boolean = false;
  isDirty:boolean = false;
  afterRepoReview: any;
  isDataRecived: boolean = false;
  appform!: UntypedFormGroup;
  isRePermission: boolean = false;
  substatus:any=[];
  curDate:any;
  isRepoPermission:boolean=false;
  DATE_FORMAT_MMDDYYYY = ConstantConfig.DATE_FORMAT_MMDDYYYY;
  submitRepoReviewPreviewData: any = null;
  substatus_data_json:any=[
    {code:'B01',value:'Bankruptcy Review'},
    {code:'B04',value:'Chapter 7 Discharged'},
    {code:'C02',value:'Account is past due'},
    {code:'I03',value:'Warranty Cancellation'},
    {code:'L01',value:'Legal Case'},
    {code:'R02',value:'Repo Ordered'},
    {code:'R05',value:'Impound Repossessed'},
    {code:'T02',value:'Pending Title Receipt'},
    {code:'B02',value:'Chapter 13 Payment Plan'},
    {code:'B05',value:'Chapter 7 Filed'},
    {code:'I01',value:'Insurance Review'},
    {code:'I04',value:'GAP Insurance Claim'},
    {code:'L02',value:'Dlr Compliance Case'},
    {code:'R03',value:'Vol. Repossessed'},
    {code:'R06',value:'Liquidated (Sold)'},
    {code:'T03',value:'Pending Title Release'},
    {code:'B03',value:'Chapter 13 Filed'},
    {code:'C01',value:'Account is current'},
    {code:'I02',value:'Total Loss Claim'},
    {code:'I05',value:'Processed All Claims'},
    {code:'R01',value:'Repo Review'},
    {code:'R04',value:'Vol. Repossessed'},
    {code:'T01',value:'New State Registration'},

 ];
  constructor(
    private httpService: HttpService,
    private msgService: MessageService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private permissionService: PermissionService,
    private broadCastService:BroadcastService,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
  this.isRePermission = this.permissionService.isSpecialPermission('submit_repo_review');
  }

  ngOnInit(): void {
    this.appform = new UntypedFormGroup({
      // id: new FormControl(null, [Validators.required]),
      employee_initials: new UntypedFormControl(null, [Validators.required]),
       pin: new UntypedFormControl(null, [Validators.required]),
     });
     this.getSubmitRepoReviewPreview();
    this.curDate = new Date();



  }

  convertLocalTimeZone(d:any)
  {
    if(d==''||d==null){return null}
    var testDateUtc = moment.tz(d,ConstantConfig.SERVER_TIMEZONE);
    return moment(testDateUtc).local().format("MM/DD/YYYY hh:mm:ss A");
  }
  convertLocalTimeZoneWithoutTime(d:any)
  {
    if(d==''||d==null){return null}
    var testDateUtc = moment.tz(d,ConstantConfig.SERVER_TIMEZONE);
    return moment(testDateUtc).local().format("MM/DD/YYYY");
  }

  conver_To_Camel_Case(str:string)
  {
    let STR = str.toLowerCase()
		.trim()
		.split(' ')
		.map(word => word.replace(word[0], word[0].toString().toUpperCase()))
		.join(' ');
	return STR;
  }

  submitRepoReview () {
    const url = `${PathConfig.SUBMIT_REPO_REVIEW}`;

      this.httpService.post(url, {loan_id: this.loanID}).subscribe((response: any) => {
        if(response && response.loan_repo_review) {
          this.isDataRecived = true;
          this.afterRepoReview = response.loan_repo_review
        }
        //this.modalService.dismissAll();
        this.msgService.showSuccess(response?.message);
        this.submitted = false;
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACTIVITY);
        // this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
        this.modalService.dismissAll();
      },
      (err) => {
        this.isDataRecived = false;
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
        this.submitted = false;
      });
  }




  verifyPin()
  {
    if(this.appform.valid)
      {
        const params = this.appform.getRawValue();
        const url = `${PathConfig.VERIFY_PIN}`;
        this.httpService.post(url, params).subscribe({
          next : (res) => {


            if(res?.message!='User not found' && res?.message!='User validation failed')
            {
          this.msgService.showSuccess(res?.message);
          this.isRePermission=true;
          this.submitRepoReview();
            }
          else
          {
          this.msgService.showError(res?.message);
          }
            this.submitted = false;
            this.isDirty = false;
            this.appform.reset();


          },
          error : (err) => {
            const msgList = Utility.getErrorMessage(err.error);
            msgList.forEach((msg) => this.msgService.showError(msg));
          }
        });

      }


  }

   /**
   * Get Approval Modal
   * @param content
   */
   openGetApprovalModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'sm', windowClass:'draggable-container',
    backdrop:false });
  }

  getSubmitRepoReviewPreview()
  {
    const url = `${PathConfig.SUBMIT_KICKBACK_PREVIEW}?loan_id=${this.loanID}&type=SUBMIT_REPO_REVIEW`;
    this.httpService.get(url,true).subscribe(
      (response) => {

        this.submitRepoReviewPreviewData = response?.preview_data;

      },
      (err) => {
        // this.msgService.showError(err.error.message);
      }
    );
  }

}
