<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
        <ng-container>
            <form #f="ngForm" [formGroup]="call" novalidate>
                <div class="modal-header align-items-center " >
                    <span class="icon icon-cirlce">
                        <img class="icon-light" src="../assets/images/header/phone-icon.png" alt="" aria-hidden="true" width="44" />
                        <img class="icon-dark" src="../assets/images/header/phone-icon-dark.png" alt="" aria-hidden="true" width="44" />
                    </span>
                    <h6 class="modal-title w-100 text-center" id="modal-basic-title"></h6>
                    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="phone">
                        <div class="phone-keys">
                            <input type="text" *ngIf="maskOff" class="phone-screen"  value="{{(dailing_no| mask: US_PHONE_MASKING)}}" placeholder="(000) 000-0000" />
                            <input type="text" *ngIf="!maskOff" class="phone-screen" [mask]="US_PHONE_MASKING" value="" placeholder="(000) 000-0000" />
                            <button type="button" class="action">{{"Call"|translate}}</button>
                            <button type="button" class="operator">{{"Mute"|translate}}</button>
                            <button type="button" class="operator">{{"Hold"|translate}}</button>
                            <button type="button" class="operator">{{"Transfer"|translate}}</button>
                            <button type="button" class="operator">{{"Line 2"|translate}}</button>
                            <button type="button" class="operator">{{"Line 2"|translate}}</button>
                            <button type="button" class="operator">{{"To Account"|translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</div>